import React, { useState, useEffect } from "react";
import { HomeAbout } from "./HomeAbout";

import { Link } from "react-router-dom";
import homeoffer from "../images/homeoffer.webp";
import ReviewSlider from "./ReviewSlider";
import { AiFillCheckCircle } from "react-icons/ai";
import { Slider } from "./Slider";
import product1 from "../images/product/product1.webp";
import product2 from "../images/product/product2.webp";
import product3 from "../images/product/product3.webp";
import product4 from "../images/iiot.webp";
import { Button } from "react-bootstrap";
import palletizing_Cell from "../images/solution/palletizing_Cell.webp";
import solution2 from "../images/solution/truck-loading.webp";
import pick_place from "../images/solution/pick_place.webp";
import packing from "../images/solution/packing.webp";

import service from "../images/service.webp";
import HomeIndustry from "./HomeIndustry";
import newupdate from "../page/NewsUpdate";
export const Home = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="">
        <Slider />
      </div>
      <div className="mt-3">
        <HomeAbout />
      </div>

      <section className="section_paddinging partnerSec1">
        <div className="container py-3">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="about_us_img pt-lg-5">
                <img src={homeoffer} width="100%" className="pe-lg-5" alt="" />
              </div>
            </div>
            <div className="col col-md-12 col-sm-12 col-lg-7 ps-lg-3 py-lg-4">
              <div className="whatWeoffer">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start text-light "
                >
                  We Offer
                </h1>
                <p
                  className="card-paragraph mt-3"
                  style={{ textAlign: "justify" }}
                >
                  Airi-Sense provides data-driven automation, software
                  solutions, and modular services for reliability.
                </p>
                <div className="row">
                  <div className="col-lg-6 pe-4 ">
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className="ms-2 fs-5 text-white">
                        Explore our{" "}
                        <span style={{ color: "var(--orange)" }}>
                          cutting-edge modular solutions
                        </span>{" "}
                        & products ensuring efficiency at every step.
                      </span>
                    </div>
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className="text-justify ms-2 fs-5 text-white">
                        {" "}
                        <span style={{ color: "var(--orange)" }}>
                          Tailored solutions
                        </span>
                        , based on our own products, meeting current and future
                        needs.
                      </span>
                    </div>
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className="ms-2 fs-5 text-white">
                        {" "}
                        Adopt a{" "}
                        <span style={{ color: "var(--orange)" }}>
                          data-driven approach to ensure
                        </span>{" "}
                        precision and alignment
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className=" ms-2 fs-5 text-white">
                        {" "}
                        Lead in{" "}
                        <span style={{ color: "var(--orange)" }}>
                          energy conservation
                        </span>{" "}
                        with meticulously crafted,{" "}
                        <span style={{ color: "var(--orange)" }}>
                          sustainable green solutions{" "}
                        </span>{" "}
                        .
                      </span>
                    </div>
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className=" ms-2 fs-5 text-white">
                        {" "}
                        Transform your{" "}
                        <span style={{ color: "var(--orange)" }}>
                          {" "}
                          business value{" "}
                        </span>
                        with our solutions and products for{" "}
                        <span style={{ color: "var(--orange)" }}>
                          long-term success
                        </span>{" "}
                        .
                      </span>
                    </div>
                    <div className="d-flex my-2">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className="ms-2 fs-5 text-white">
                        {" "}
                        Realistic, timely project delivery - unwavering{" "}
                        <span style={{ color: "var(--orange)" }}>
                          commitment to punctuality
                        </span>{" "}
                        assured.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-11">
                    <div className="d-flex">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                      </span>
                      <span className="ms-2 fs-5 text-white">
                        {" "}
                        Elevate your experience with our{" "}
                        <span style={{ color: "var(--orange)" }}>
                          customer-centric approach
                        </span>{" "}
                        , in every action we undertake.
                      </span>
                    </div>
                  </div>
                </div>
                <Link className="ms-2" to="/solutions">
                  {" "}
                  <div className="ms-lg-4 btn btn-sm px-5 btn-borderd button-1 my-3 ms-lg-2">
                    Read More
                  </div>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="py-lg-4 py-3 bg-light ">
        <div className="container homeProduct">
          <h1 className="pb-lg-4 heading-homepage" style={{ fontSize: "55px" }}>
            Our Products
          </h1>
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={product1}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    {" "}
                    ​Smart Robotic Solutions{" "}
                    <Link
                      to="/product/smart-robotic-details"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-3   shadow-sm homeproductcard">
                <img
                  src={product2}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Material Mgt Solutions{" "}
                    <Link
                      to="/product/material-mangement-details"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={product3}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Truck Loading Solutions{" "}
                    <Link
                      to="/product/truck-loading-details"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={product4}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    ​​​​​​​IIOT and Analytics​
                    <Link
                      to="/product/truck-loading-details"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <Link to="/product">
              {" "}
              <span className="text-right">
                {" "}
                <Button className="fs-5 fw-bold px-5 btn btn-sm">
                  See All
                </Button>
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div class="py-lg-5 py-3" style={{ backgroundColor: "var(--orange)" }}>
        <div className="container homeProduct">
          <h1
            className="pb-3 heading-homepage text-white"
            style={{ fontSize: "55px" }}
          >
            Our Solutions
          </h1>
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={palletizing_Cell}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    {" "}
                    Palletizing{" "}
                    <Link
                      to="/solutions/PalletizingDetails"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-3   shadow-sm homeproductcard">
                <img
                  src={pick_place}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Pick & Place{" "}
                    <Link
                      to="/solutions/PickPlaceDetails"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={packing}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Packaging Solution{" "}
                    <Link
                      to="/solutions/PackagingDetails"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={solution2}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Truck Loading{" "}
                    <Link
                      to="/solutions/TruckDetails"
                      class="text-decoration-none"
                    ></Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3">
            <Link to="/product">
              {" "}
              <Button
                className="fs-5 fw-bold bg-white btn btn-sm px-5"
                style={{ color: "var(--orange)" }}
              >
                See All
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div class="py-lg-5 py-3 bg-light">
        <HomeIndustry />
      </div>

      <section className="section_padding position-relative mt-lg-5 ">
        <hr id="serviceHr" />
        <div className="container homeProduct">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-6 mb-lg-5 ps-lg-3">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "2px",
                  }}
                  className="text-start text-md-center pt-3"
                >
                  Customer Services
                </h1>
                <p
                  className="card-paragraph pt-2 pb-0 mb-0"
                  style={{ textAlign: "justify" }}
                >
                  Embark on a journey of unparalleled customer service with
                  Airi-Sense Technologies' automation solutions, where
                  excellence is not just a commitment but a standard.
                </p>
                <p
                  className="card-paragraph pb-0 mb-0"
                  style={{ textAlign: "justify" }}
                >
                  Your satisfaction is our priority. Experience innovative,
                  reliable automation solutions and elevate your operations.
                </p>
                {/* <p
                  className="card-paragraph"
                  style={{ textAlign: "justify" }}
                ></p> */}
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6 mb-lg-5 px-lg-5">
              <div className="">
                <img
                  src={service}
                  width="100%"
                  height="312px"
                  className="shadow rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row justify-content-around py-3">
          <h1 className="mb-4 heading-homepage">Latest News</h1>
          {newupdate.map((item, index) => (
            <div class="col-lg-4 col-md-6 col-sm-12 p-2">
              <div
                className={`post-module ${isHovered ? "hovered" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="thumbnail">
                  <img src={item.img} />
                </div>
                <div className="post-content">
                  <p className="category">{item.date}</p>
                  <p className="newDes text-black fs-5">
                    {item.des}{" "}
                    <a
                      className="text-decoration-none newRead text-warning fs-6"
                      href={item.link}
                    >
                      Read More....
                    </a>
                  </p>
                  <div className="post-meta"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
