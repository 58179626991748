import React, { useEffect, useState } from "react";
import smart_bnr from "../../images/product/product_details/smartConveyingBnr.webp";
import smartConveying from "../../images/product/product_details/smartConveying.webp";
import icon1 from "../../images/product/icons/spiral.png";
import icon2 from "../../images/product/icons/roll-belt.png";
import icon3 from "../../images/product/icons/roller-chain.png";
import smartconveingMobileView from "../../images/product/product_details/smartconveingMobileView.webp";
import { FaDownload } from "react-icons/fa6";
import catelog1 from "../../pdfCattalog/Spirals-Belt & Gravity.pdf";
import catelog2 from "../../pdfCattalog/Belt & RollerConveyor's.pdf";
import catelog3 from "../../pdfCattalog/Roller & ChainConveyor's.pdf";
import DilogueBox from "../../component/DilogueBox";

export const SmartConveyingDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );

  // Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? smartconveingMobileView : smart_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  ​​​​​​​​​Smart Conveying Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Conveyors transform operational efficiency, replacing manual
                  transport with reliable, ergonomic solutions. Whether
                  standalone or integrated, they enhance throughput, alleviate
                  bottlenecks, and optimize labor productivity in diverse
                  processes.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Our modular systems are adapted to your business needs,
                  imparting efficiency across warehouses, distribution centers,
                  or production facilities. We customize solutions using a
                  spectrum of technologies and design tools.
                </p>

                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Airisense experts ensure a streamlined and productive
                  operational environment by tailoring solutions to align
                  seamlessly with your objectives.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-lg-5 ">
              <div className="about_us_img ">
                <img
                  src={smartConveying}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                }}
                className="text-center text-white display-4"
              >
              Revolutionize Efficiency with Smart Conveyors
              </h1>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Spiral - Belt & Gravity{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    The gravity spiral conveyor utilizes inclined spiral loops
                    and supporting rollers, requiring no external power. It
                    simplifies material handling with minimal maintenance,
                    making it ideal for uncomplicated, low-maintenance
                    applications.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Belt & Roller Conveyor{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Belt conveyors efficiently move standardized items of
                    varying sizes with low noise and minimal vibration. Electric
                    drives ensure operational smoothness and immediate torque,
                    making them indispensable in diverse industrial
                    applications.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Roller & Chain Conveyor{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Precision-engineered pallet handling conveyors use chains
                    and rollers for seamless movement of heavy loads, ensuring
                    secure transport and enhancing system throughput in
                    industries dealing with substantial products.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Special Conveyors{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Reduce downtime, achieve cost savings over time. Reduce
                    downtime and achieve cost savings over time.Reduce downtime,
                    achieve cost savings over time. Reduce downtime and achieve
                    cost savings over time.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a className="custom-btn btn-7 text-center text-decoration-none" href="#" download>
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>  */}
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* Download modal */}
        <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
