import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import logolarge from "../images/Airi_Sense.png";
import logoSmall from "../images/logoSmall.png";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";

export const Header = () => {
  const [showNavbar, setShowNavbar] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isToggled, setIsToggled] = useState(false);
  const navbarRef = useRef(null);

  const handleShowNavbar = () => {
    setShowNavbar("");
  };

  const closeMenu = () => {
    setShowNavbar("hide");
    setIsToggled(false); // Close the mobile menu
  };

  useEffect(() => {
    // Update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const hideOnMobileTablet = {
    display: windowWidth <= 768 ? "none" : "block",
  };

  return (
    <>
      <Navbar
        expand="lg"
        ref={navbarRef}
        className="bg-body-tertiary"
        sticky="top"
        style={{ boxShadow: "6px 2px 15px 1px rgba(0,0,0,0.8)" }}
      >
        <Container>
          <NavLink to="/">
            <div className="logo">
              {windowWidth > 395 ? (
                <img src={logolarge} className="w-75 py-2" />
              ) : (
                <img src={logoSmall} className="w-75 d-flex py-1 px-0" />
              )}
            </div>
          </NavLink>
          <Navbar.Toggle
            aria-controls="navbarScroll"
          />
          <Navbar.Collapse id="navbarScroll" className={`${showNavbar}`}>
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "150px" }}
              navbarScroll
            >
              <NavLink
                to="/product"
                className="mx-4 nav-link-products "
                onClick={closeMenu}
              >
                Products
              </NavLink>
              <NavLink
                to="/solutions"
                className="mx-4 nav-link-products"
                onClick={closeMenu}
              >
                Solutions
              </NavLink>
              <NavLink
                to="/industries"
                className="mx-4 nav-link-products"
                onClick={closeMenu}
              >
                Industries
              </NavLink>
              <NavLink
                to="/careers"
                className="mx-4 nav-link-products"
                onClick={closeMenu}
              >
                Careers
              </NavLink>
            </Nav>
            <Form className="d-flex ms-auto d-flex justify-content-center">
              <Link to="/contact" className="text-decoration-none">
                <Button
                  className="btn btn-lg btn-borderd"
                  style={{ border: "none", ...hideOnMobileTablet }}
                >
                  {" "}
                  Contact Us
                </Button>
              </Link>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
