import React, { useEffect, useState } from "react";
import material_bnr from "../../images/product/product_details/materialManageBnr.webp";
import materialManage from "../../images/product/product_details/materialMange.webp";
import icon1 from "../../images/product/icons/inline.png";
import icon2 from "../../images/product/icons/verticle.png";
import icon3 from "../../images/product/icons/vertiLift.png";
import icon4 from "../../images/product/icons/continous.png";
import materialmanageMobileView from "../../images/product/product_details/materialmanageMobileView.webp";
import catelog1 from "../../pdfCattalog/InLineStorageModule.pdf";
import catelog2 from "../../pdfCattalog/VerticalLift - Case & Tote.pdf";
import catelog3 from "../../pdfCattalog/VerticalLift - Pallet.pdf";
import catelog4 from "../../pdfCattalog/ContinuousLift - Case & Tote.pdf";
import { FaDownload } from "react-icons/fa6";
import DilogueBox from "../../component/DilogueBox";

export const MaterialManageDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );

  // Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? materialmanageMobileView : material_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  ​Material Management Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Our Material Management product line redefines efficiency,
                  incorporating cutting-edge solutions. These innovations
                  elevate your logistics by streamlining material flow,
                  enhancing accessibility, and optimizing storage.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Our range of products ensure synchronized and efficient
                  material transfer, minimizing delays and maximizing
                  productivity. These products collectively form a robust
                  material handling ecosystem, designed to meet the demands of
                  modern industries.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Experience heightened operational fluidity, reduced downtimes,
                  and elevated productivity with our comprehensive Material
                  Management solutions tailored to meet diverse industrial
                  needs.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-5 ">
              <div className="about_us_img ">
                <img
                  src={materialManage}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                }}
                className="text-center text-white display-4 "
              >
                Efficiency Through Smart Material Management
              </h1>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      In Line Storage Module{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Our space-efficient storage modules adapt to facilities,
                    with precise engineering achieving 100% accuracy.
                    Multi-layer conveyors enhance scalability, maximizing crate
                    density for efficiency.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Vertical Lift - Case & Tote{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Durable and compact, the Vertical Lift Conveyor, with its
                    stable steel structure, operates silently, requiring minimal
                    upkeep. It efficiently transports loads up to 100 kg,
                    offering an economical, adaptable solution for diverse
                    industrial settings.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Vertical Lift - Pallet{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Revolutionize pallet transport using the Vertical Pallet
                    Lift, seamlessly automating elevation adjustments. Compact
                    and efficient, it handles up to 100 pallets per hour,
                    ensuring adaptability and reliability for cost-effective
                    processes.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Continuous Lift - Case & Tote{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Continuous lift, a modular vertical conveyor for efficient
                    material handling with moderate to high capacities, excels
                    in seamlessly transporting diverse products between floors,
                    maintaining their upright position.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog4)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* Download modal */}
        <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
