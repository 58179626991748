import React from "react";
import automotive from "../images/automotive.webp";
import chemical from "../images/chemical.webp";
import rubber from "../images/rubber.webp";
import electronic from "../images/elctronic.webp";
import industry from "../images/diverseIndustry.webp";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function HomeIndustry() {
  return (
    <div>
      <section className="position-relative">
        <hr id="industryHr" />
        <div className="container homeProduct">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-6 mb-lg-5 px-lg-4">
              <div className="about_us_img">
                <img
                  src={industry}
                  width="100%"
                  className=" shadow rounded"
                  alt=""
                />
              </div>
            </div>
            <div className="col col-md-12 col-sm-12 col-lg-6 mb-5">
              <div className="whatWeDo px-lg-3">
                <h1
                  className="heading-homepage text-black pt-4 text-md-center "
                  style={{ letterSpacing: "3px" }}
                >
                  We Serve
                </h1>
                <p
                  className="card-paragraph pt-2 mb-1"
                  style={{ textAlign: "justify" }}
                >
                  With a dedicated focus on diverse industries, Airi-Sense
                  excels in delivering tailored automation solutions for
                  manufacturing. Our collaborative approach ensures a distinct
                  competitive edge in quality, innovation, and technological
                  excellence, thereby supporting businesses in their strategic
                  growth initiatives
                </p>

                {/* <Link to="/industries">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-2">
                    Read More..
                  </div>{" "}
                </Link> */}
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={automotive}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Automotive
                    <Link to="/industries" class="text-decoration-none"></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 rounded ">
              <div class="card mx-lg-3   shadow-sm homeproductcard">
                <img
                  src={chemical}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-lg-3">
                    Chemical{" "}
                    <Link to="/industries" class="text-decoration-none"></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={rubber}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Rubber{" "}
                    <Link to="/industries" class="text-decoration-none"></Link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6  mb-3 rounded ">
              <div class="card mx-3  shadow-sm homeproductcard">
                <img
                  src={electronic}
                  class="img-fluid rounded"
                  style={{ height: "250px" }}
                />
                <div class="card-body">
                  <h4 className="py-3">
                    Electronics{" "}
                    <Link to="/industries" class="text-decoration-none"></Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <Link to="/industries">
              {" "}
              <span className="text-right">
                {" "}
                <Button className="fs-5 fw-bold btn btn-sm px-5">
                  See All
                </Button>
              </span>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
