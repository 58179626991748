import React, { useState, useEffect } from "react";
import product_bnr from "../images/product/product_bnr.webp";
import ProductMobileView from "../images/ProductMobileView.webp";
import { ListGroup } from "react-bootstrap";
import productDiscription from "../images/product/productDis.webp";

import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import "./product.css";
import { productCard } from "./ProductCards";
import { isDOMComponent } from "react-dom/test-utils";

const ProductCard = ({card}) => {

  const [isHovered, setIsHovered] = useState(false);
  
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  return (
    <Card
      className={`me-2  border shadow  border-5 shadow  cardhover border-white rounded-3  ${
        isHovered ? "hovered" : ""
      }`}
      onMouseEnter={handleHover}
      style={{
        height: "auto",
        transition: "transform 0.9s ease-in-out", // Add a transition for smoother hover effect
        transform: isHovered ? "scale(1.03)" : "scale(1)",
      }}
    >
      <Card.Img
        variant="top"
        src={card.img}
        alt={card.title}
        style={{ height: "250px" }}
      />
      <Link
        to={card.link}
        className="pt-2 fw-bold text-black"
        style={{ textDecoration: "none" }}
      >
        <Card.Body>
          <Card.Title className="pt-2 fw-bold">
            ​​​​​​​​​​​​​{card.title}
          </Card.Title>
          <ListGroup>
            {card.list.map((item, itemIndex) => (
              <ListGroup.Item
                className="text-start list-group-item"
                key={itemIndex}
              >
                <span className="productlistitem"> {item} </span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Link>
    </Card>
  );
};

export const Product = () => {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section id="our_pride">
        <div className="bnrImg">
          <img
            src={isMobileView ? ProductMobileView : product_bnr}
            width="100%"
            height="auto"
          />
        </div>
      </section>

      <section
        className="section_padding partnerSec1"
        style={{ backgroundColor: "#f1f1f1" }}
      >
        <div className="container py-lg-5 pt-2">
          <div className="row justify-content-between align-items-center">
            <div className="col col-md-12 col-sm-12 col-lg-7 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  Our Products
                </h1>
                <p
                  className="card-paragraph mt-lg-4"
                  style={{ textAlign: "justify" }}
                >
                  Airi-Sense offers you a wide range of traditional and out of
                  the box technologies for automating your existing and upcoming
                  facilities with high quality, scalability and efficiency.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Tailored to individual needs, our material handling
                  technologies are adaptable, versatile, and driven by software.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  We ensure that our in-house custom solutions are seamlessly
                  aligned with your specific requirements, promoting efficient
                  goods flow while minimizing operational expenses.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  If you are seeking a specific solution or facing a challenge
                  that requires resolution, our cutting-edge modular and
                  customized material handling solutions are designed to
                  precisely meet your needs.
                </p>
                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5">
              <div className="about_us_img">
                <img
                  src={productDiscription}
                  className="image-fluid ms-2"
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-5">
        <h1
          className=" fw-bold text-center whatWeDo pb-3"
          style={{ letterSpacing: "6px" }}
        >
          Our Products
        </h1>
        <div className="container px-lg-5">
          <div className="row justify-content-center">
            {productCard.map((card, index) => (
              <div className="col-lg-3 mx-2 mb-3">
                <ProductCard key={`pro${index}`} card={card} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
