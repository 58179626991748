import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import newupdate from "../page/NewsUpdate";

const ReviewSlider = ({ starData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const checkNumber = (number) => {
    if (number >= newupdate.length) {
      return 0;
    } else if (number < 0) {
      return newupdate.length - 1;
    }
    return number;
  };

  const changePerson = (step) => {
    setCurrentIndex((currentIndex) => checkNumber(currentIndex + step));
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <AliceCarousel
      mouseTracking
      autoPlay={true}
      autoPlayInterval={1000}
      responsive={{
        0: { items: 1 },
        420: { items: 1 },
        750: { items: 2 },
        1000: { items: 3 },
        1100: { items: 3 },
        1250: { items: 4 },
        1800: { items: 4 },
      }}
      startIndex={currentIndex}
    >
      
    </AliceCarousel>
  );
};

export default ReviewSlider;
