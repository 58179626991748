import React, { useState, useEffect } from "react";
import solution_bnr from "../../images/product/product_details/conveyingBnr.webp";
import solution_comman from "../../images/solution/solution_details/packing-cmn.webp";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import packging_bnr from "../../images/solution/solution_details/packging_bnr.webp";
import {
  package_benefits,
  package_concept,
  packaging_trends,
} from "../../component/PackageData";
import { AiFillCheckCircle } from "react-icons/ai";
export const PackagingDetails = () => {
  const [activePanel, setActivePanel] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const setActive = (number) => {
    if (activePanel == number) {
      setActivePanel(null);
    } else {
      setActivePanel(number);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img src={packging_bnr} width="100%" height="auto" />
      </div>

      <div className="container">
        <div className="row justify-content-center pb-5 pt-3">
          <h1 style={{ fontWeight: "400" }} className="py-lg-2">
            Concept of Automated Packaging
          </h1>
          {package_concept.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="py-3 px-3 conceptCard">
                <div
                  className="conceptContent px-4 py-2"
                  style={{ background: item.background }}
                >
                  <h4>{item.heading}</h4>
                  <p>{item.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="container py-lg-5 px-lg-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className=" text-white">
                <h1 style={{ textAlign: "justify", fontWeight: "400" }}>
                Purpose & Benefits
                </h1>
                <p
                  className="mt-4 w-100  fs-3  fw-light "
                  style={{ textAlign: "justify" }}
                >
                  The purpose of robotic packaging is to revolutionize and
                  optimize the packaging process by leveraging the capabilities
                  of robotic technology. This results in increased efficiency,
                  cost savings, improved safety, and consistent high-quality
                  packaging across diverse industries.
                </p>
              </div>
            </div>

            <div className="row whatWeDo">
              {package_benefits.map((item, index) => (

                <div className="col-lg-6 ">
                  <div className="d-flex ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <p className=" mb-0 ps-2 text-white">{item.heading}</p>
                  </div>
                  <p className=" fs-5 ps-3 pe-lg-5  text-white">{item.des}</p>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }} className="pb-lg-0 pb-4">
        <h1 style={{ fontWeight: "400" }} className="pt-lg-4">
          Trends of Packaging
        </h1>
        <div className="container">
          <div className="row pb-5 justify-content-lg-center">
            {packaging_trends.map((item, index) => (
              <div className="col-lg-6">
                <div className=" d-lg-flex  flex-lg-column">
                  <div id="accordion" className="trendcard px-lg-3" key={index}>
                    <div
                      className={`text-start shadow mt-4 ${
                        activePanel === index + 1 ? "active" : ""
                      }`}
                      style={{
                        backgroundColor: "var(--orange)",
                        border: "solid var(--white)",
                      }}
                    >
                      <div className="card-body px-2 py-2">
                        <a
                          onClick={() => setActive(index + 1)}
                          className="fs-5 d-flex justify-content-between"
                          style={{
                            color: "var(--white)",
                            textDecoration: "none",
                            fontWeight: "400px",
                          }}
                          data-bs-toggle="collapse"
                          href={`#collapse${index + 1}`}
                          aria-expanded={activePanel === index + 1}
                          aria-controls={`collapse${index + 1}`}
                        >
                          <span>{item.heading}</span>
                          {activePanel !== index + 1 ? (
                            <BiPlusMedical className="me-lg-3 mt-2" />
                          ) : (
                            <FaMinus className="me-lg-3 mt-2" />
                          )}
                        </a>
                        <p
                          className="collapse text-white pt-3 fs-6"
                          id={`collapse${index + 1}`}
                          data-bs-parent="#accordion"
                          style={{
                            backgroundColor: "var(--orange)",
                            textAlign: "justify",
                            transition: "all 0s ease-in-out 0s"
                          }}
                        >
                          {item.des}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
