import React, { useState, useEffect } from "react";
import truck_bnr from "../../images/product/product_details/truckLoadingBnr.webp";
import truckLoading from "../../images/product/product_details/truckLoading.webp";
import iconpro from "../../images/product/product_details/manufacturing.png";
import truckloadingMobileView from "../../images/product/product_details/truckloadingMobileView.webp";
import { FaDownload } from "react-icons/fa6";
import icon1 from "../../images/product/icons/inline.png";
import icon2 from "../../images/product/icons/expandable.png";
import icon3 from "../../images/product/icons/verticle.png";
import icon4 from "../../images/product/icons/auto-load.png";
import catelog1 from "../../pdfCattalog/InclinedLoadingConveyor.pdf";
import catelog2 from "../../pdfCattalog/ExpandableConveyor.pdf";
import catelog3 from "../../pdfCattalog/TelescopicConveyor.pdf";
import DilogueBox from "../../component/DilogueBox";

export const TruckLoadingDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );

  // Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? truckloadingMobileView : truck_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  ​​​​​​​​​​​Truck Loading Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Transform your approach to cargo management through our Truck
                  Loading Solutions, which redefine the efficiency of material
                  loading and unloading processes. Experience a heightened focus
                  on workplace safety, where our solutions act as a shield,
                  preventing injuries and safeguarding goods throughout
                  transportation.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Effortlessly accommodating various cargo sizes, from sizable
                  boxes to cartons and polybags, our solutions provide
                  flexibility in manual or automatic control, tailoring to your
                  preferences.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Transform cargo handling processes with our state-of-the-art
                  Truck Loading Solutions, enhancing efficiency, safety, and
                  overall productivity in loading operations.
                </p>
                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-lg-5 ">
              <div className="about_us_img ">
                <img
                  src={truckLoading}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                }}
                className="text-center text-white display-4"
              >
                Transforming Truck Operations Seamlessly
              </h1>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Inclined Loading Conveyor{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Transform your loading and unloading processes using our
                    Inclined Loading Conveyor. This portable system, tailored
                    for non-palletized loads, adapts to diverse environments,
                    conserving warehouse space while ensuring precision.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Expandable Conveyor{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Showcasing exceptional flexibility, expandable conveyors
                    seamlessly extend from a store's interior to a trailer's
                    front wall, optimizing loading and unloading efficiency with
                    a dynamic design for industrial versatility.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Telescopic Conveyor{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Telescopic conveyors streamline material handling with rapid
                    loading and unloading through their extendable design,
                    reducing labor costs, and improving productivity. Their
                    efficiency proves invaluable in industrial workflows.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>

                {/* <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Auto Loading Platform{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Reduce downtime, achieve cost savings over time. Reduce
                    downtime and achieve cost savings over time.Reduce downtime,
                    achieve cost savings over time. Reduce downtime and achieve
                    cost savings over time.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a className="custom-btn btn-7 text-center text-decoration-none" href="#" download>
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* Download modal */}
        <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
