import React, { useState, useEffect } from "react";
import solution_bnr from "../../images/solution/solution_details/track_trace_bnr.webp";
import solution_comman from "../../images/solution/solution_details/track-trace-cmn.webp";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import track_traceMobileView from "../../images/solution/solution_details/track_traceMobileView.webp";
import { trace_benefits, trace_concept, trace_trends } from "../../component/TrackTraceData";
import { AiFillCheckCircle } from "react-icons/ai";

export const TrackTraceDetails = () => {
  const [activePanel, setActivePanel] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  const setActive = (number) => {
    if (activePanel == number) {
      setActivePanel(null);
    } else {
      setActivePanel(number);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? track_traceMobileView : solution_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div className="container">
        <div className="row justify-content-center pb-5 pt-3">
          <h1 style={{ fontWeight: "400" }} className="py-lg-2">
            Concept of Track & Trace
          </h1>
          {trace_concept.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="py-3 px-3 conceptCard">
                <div
                  className="conceptContent px-4 py-2"
                  style={{ background: item.background }}
                >
                  <h4>{item.heading}</h4>
                  <ul style={{listStyleType:"disc"}}>
                  <li>{item.des1}</li>
                  <li>{item.des2}</li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="container py-lg-5 px-lg-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className=" text-white">
                <h1 style={{ textAlign: "justify", fontWeight: "400" }}>
                Purpose & Benefits
                </h1>
                <p
                  className="mt-4 w-100  fs-3  fw-light "
                  style={{ textAlign: "justify" }}
                >
                  The purpose of robotic packaging is to revolutionize and
                  optimize the packaging process by leveraging the capabilities
                  of robotic technology. This results in increased efficiency,
                  cost savings, improved safety, and consistent high-quality
                  packaging across diverse industries
                </p>
              </div>
            </div>

            <div className="row whatWeDo">
              {trace_benefits.map((item, index) => (
                  <div className="col-lg-6 ">
                  <div className="d-flex ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <p className=" mb-0 ps-2 text-white">{item.heading}</p>
                  </div>
                  <p className=" fs-5 ps-3 pe-lg-5  text-white">{item.des}</p>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ backgroundColor: "#f1f1f1" }} className="pb-lg-0 pb-4">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="about_us_img ">
                <img
                  src={solution_comman}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-7 pt-2 ps-lg-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  Case Handeling Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Airi-Sense Technologies, a future-focused robotic & automation
                  to be part of an innovative and rapidly evolving company.
                </p>
              </div>

              <div id="accordion">
                <div
                  className="row text-start shadow mt-4 "
                  style={{
                    backgroundColor: "var(--orange)",
                    border: " solid Var(--white)",
                  }}
                >
                  <div className="card-body px-3 py-3">
                    <a
                      onClick={() => setActive("1")}
                      className="fs-4 fw-bold d-flex justify-content-between "
                      style={{ color: "var(--white)", textDecoration: "none" }}
                      data-bs-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="false"
                      aria-controls="collapse1"
                    >
                      <span>Case Handeling Solutions</span>{" "}
                      {activePanel !== "1" ? (
                        <BiPlusMedical className="me-lg-3 mt-2" />
                      ) : (
                        <FaMinus className="me-lg-3 mt-2" />
                      )}
                    </a>
                    <p
                      className="collapse text-white pt-3 fs-5 "
                      id="collapse1"
                      data-bs-parent="#accordion"
                      style={{ backgroundColor: "var(--orange)" }}
                    >
                      Some placeholder content for the collapse component. This
                      panel is hidden by default but revealed when the user
                      activates the relevant trigger.
                    </p>
                  </div>
                </div>
                <div
                  className="row text-start shadow mt-4"
                  style={{
                    backgroundColor: "var(--orange)",
                    border: " solid Var(--white)",
                  }}
                >
                  <div className="card-body px-3 py-3">
                    <a
                      onClick={() => setActive("2")}
                      className="fs-4 fw-bold d-flex justify-content-between "
                      style={{ color: "var(--white)", textDecoration: "none" }}
                      data-bs-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      <span>Case Handeling Solutions</span>{" "}
                      {activePanel !== "2" ? (
                        <BiPlusMedical className="me-lg-3 mt-2" />
                      ) : (
                        <FaMinus className="me-lg-3 mt-2" />
                      )}
                    </a>
                    <p
                      className="collapse text-white pt-3 fs-5 "
                      id="collapse2"
                      data-bs-parent="#accordion"
                      style={{ backgroundColor: "var(--orange)" }}
                    >
                      Some placeholder content for the collapse component. This
                      panel is hidden by default but revealed when the user
                      activates the relevant trigger.
                    </p>
                  </div>
                </div>

                <div
                  className="row text-start shadow mt-4"
                  style={{
                    backgroundColor: "var(--orange)",
                    border: " solid Var(--white)",
                  }}
                >
                  <div className="card-body px-3 py-3">
                    <a
                      onClick={() => setActive("3")}
                      className="fs-4 fw-bold d-flex justify-content-between "
                      style={{ color: "var(--white)", textDecoration: "none" }}
                      data-bs-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <span>Case Handeling Solutions</span>{" "}
                      {activePanel !== "3" ? (
                        <BiPlusMedical className="me-lg-3 mt-2" />
                      ) : (
                        <FaMinus className="me-lg-3 mt-2" />
                      )}
                    </a>
                    <p
                      className="collapse text-white pt-3 fs-5 "
                      id="collapse3"
                      data-bs-parent="#accordion"
                      style={{ backgroundColor: "var(--orange)" }}
                    >
                      Some placeholder content for the collapse component. This
                      panel is hidden by default but revealed when the user
                      activates the relevant trigger.
                    </p>
                  </div>
                </div>
                <div
                  className="row text-start shadow mt-4"
                  style={{
                    backgroundColor: "var(--orange)",
                    border: " solid Var(--white)",
                  }}
                >
                  <div className="card-body px-3 py-3">
                    <a
                      onClick={() => setActive("4")}
                      className="fs-4 fw-bold d-flex justify-content-between "
                      style={{ color: "var(--white)", textDecoration: "none" }}
                      data-bs-toggle="collapse"
                      href="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      <span>Case Handeling Solutions</span>{" "}
                      {activePanel !== "4" ? (
                        <BiPlusMedical className="me-lg-3 mt-2" />
                      ) : (
                        <FaMinus className="me-lg-3 mt-2" />
                      )}
                    </a>
                    <p
                      className="collapse text-white pt-3 fs-5 "
                      id="collapse4"
                      data-bs-parent="#accordion"
                      style={{ backgroundColor: "var(--orange)" }}
                    >
                      Some placeholder content for the collapse component. This
                      panel is hidden by default but revealed when the user
                      activates the relevant trigger.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div style={{ backgroundColor: "#f1f1f1" }} className="pb-lg-0 pb-4">
          <h1 style={{fontWeight:"400"}} className="pt-lg-4">Trends of Track & Trace</h1>
        <div className="container">
          <div className="row pb-5 justify-content-lg-center">
          {trace_trends.map((item, index) => (
            <div className="col-lg-6 ">
              <div className=" d-lg-flex  flex-lg-column ">
              <div id="accordion" className="trendcard px-lg-3"  key={index}>
               
                  <div
                    className={`text-start shadow mt-4 ${
                      activePanel === index + 1 ? "active" : ""
                    }`}
                    style={{
                      backgroundColor: "var(--orange)",
                      border: "solid var(--white)",
                    }}
                  >
                    <div className="card-body px-2 py-2" >
                      <a
                        onClick={() => setActive(index + 1)}
                        className="fs-5 fw-bold d-flex justify-content-between"
                        style={{  
                          color: "var(--white)",
                          textDecoration: "none",
                        }}
                        data-bs-toggle="collapse"
                        href={`#collapse${index + 1}`}
                        aria-expanded={activePanel === index + 1}
                        aria-controls={`collapse${index + 1}`}
                      >
                        <span>{item.heading}</span>
                        {activePanel !== index + 1 ? (
                          <BiPlusMedical className="me-lg-3 mt-2" />
                        ) : (
                          <FaMinus className="me-lg-3 mt-2" />
                        )}
                      </a>
                      
                      <p
                        className="collapse text-white pt-3 fs-6"
                        id={`collapse${index + 1}`}
                        data-bs-parent="#accordion"
                        style={{ backgroundColor: "var(--orange)", textAlign:"justify",transition: "all 0s ease-in-out 0s" }}
                      ><span  className="h5 pe-2" style={{fontWeight:"800px !important"}}>{item.subhead}</span>
                        {item.des}
                      </p>
                    </div>
                  </div>
               
              </div>
              </div>
             
              
            </div>
             ))}
          </div>
        </div>
      </div>
    </>
  );
};
