import track_trace from "../images/solution/tracktrace.webp";
import pakeging from "../images/solution/packing.webp";
import checkWeight from "../images/solution/check-weight.webp";
import loading from "../images/solution/truck-loading.webp";
import cell from "../images/solution/palletizing_Cell.webp";
import inline from "../images/solution/inline-storage.webp";

export const solutionCard = [
  {
    img: cell,
    link:"PalletizingDetails",
    title: "Palletizing",
    list: [
      
    ],
  },
  {
    img: pakeging,
    link:"PackagingDetails",
    title: "Packaging",
    list: [
      
    ],
  },
  {
    img: track_trace,
    link:"TrackTraceDetails",
    title: " Track & Trace​",
    list: [],
  },
  {
    img: checkWeight,
    link:"WeightRejectDetails",
    title: "Weighing & Rejection​",
    list: [],
  },
  {
    img: inline,
    link:"InlineStorageDetails",
    title:"In Line Storage​",
    list: [],
  },
  {
    img:  loading,
    link:"TruckDetails",
    title: "Truck Loading",
    list: [
     
    ],
  },


];
