export const truckload_trends = [
  {
    id: 1,
    heading: "Automation and Robotics",
    subhead: "Fully Automated Loading:",
    des: "Increasing adoption of fully automated truck loading solutions using robotics and automated guided vehicles (AGVs) to streamline the loading process, reduce manual labor, and enhance efficiency.",
  },

  {
    id: 2,
    heading: "Integration with Industry 4.0",
    subhead: "IoT Connectivity:",
    des: "Integration with the Internet of Things (IoT) for real-time monitoring, data collection, and analysis, allowing manufacturers to optimize loading processes and make data-driven decisions.",
  },

  {
    id: 3,
    heading: "Artificial Intelligence (AI) and Machine Learning (ML)",
    subhead: "Predictive Analytics:",
    des: "Implementation of AI and ML algorithms for predictive analytics, enabling systems to anticipate loading requirements, optimize configurations, and improve overall efficiency.",
  },

  {
    id: 4,
    heading: "Sustainability and Energy Efficiency",
    subhead: "Green Loading Solutions:",
    des: "Growing emphasis on sustainability, with the development of energy-efficient loading solutions and technologies to reduce environmental impact.",
  },

  {
    id: 5,
    heading: "Advanced Sensor Technologies",
    subhead: "Sensor Integration:",
    des: "Continued advancements in sensor technologies, including vision systems, LiDAR, and RFID, to enhance precision, safety, and automation in the loading process.",
  },

  {
    id: 6,
    heading: "Load Optimization Software",
    subhead: "Smart Load Planning:",
    des: "Implementation of load optimization software that considers factors such as weight distribution, cargo types, and truck capacity to maximize load efficiency.",
  },

  {
    id: 7,
    heading: "Real-time Monitoring and Visibility",
    subhead: "End-to-End Visibility:",
    des: "Deployment of systems providing real-time monitoring and visibility throughout the loading process, allowing for better control and management of logistics operations.",
  },

  {
    id: 8,
    heading: "Mobile Technology Integration",
    subhead: "Mobile Applications:",
    des: "Integration of mobile technologies for remote monitoring and control of truck loading processes, providing flexibility and accessibility for logistics managers.",
  },

  {
    id: 9,
    heading: "Enhanced Safety Features",
    subhead: "Safety Innovations:",
    des: "Integration of advanced safety features, such as collision avoidance systems and automated emergency braking, to enhance worker and equipment safety during the loading process.",
  },

  {
    id: 10,
    heading: "Flexible and Modular Solutions",
    subhead: "Adaptability:",
    des: "Development of flexible and modular truck loading solutions that can adapt to various types of cargo, loading configurations, and production requirements.",
  },

  {
    id: 11,
    heading: "Blockchain for Traceability",
    subhead: "Enhanced Traceability:",
    des: "Exploration of blockchain technology to enhance traceability in the loading process, providing a transparent and secure record of goods movement.",
  },

  {
    id: 12,
    heading: "Human-Machine Collaboration",
    subhead: "Collaborative Robotics:",
    des: "The use of collaborative robots (cobots) and human-machine interfaces to facilitate efficient collaboration between automated systems and human operators.",
  },

  {
    id: 13,
    heading: "Integration with Warehouse Management Systems (WMS)",
    subhead: "Seamless Information Flow:",
    des: "Tighter integration with Warehouse Management Systems for a seamless flow of information, optimizing coordination between warehouse operations and truck loading.",
  },

  {
    id: 14,
    heading: "Cybersecurity Measures",
    subhead: "Security Protocols:",
    des: "Implementation of robust cybersecurity measures to protect automated loading systems from potential cyber threats, ensuring the secure operation of loading processes.",
  },
];

export const truckload_concept = [
  {
    id: 1,
    heading: "Conveyor Systems",
    des: "Conveyor belts or roller conveyors are used to transport goods from the warehouse or production area to the loading dock and in Trucks to ensure minimal movements of operators.",
    background: "var(--orange)",
  },

  {
    id: 2,
    heading: "Loading Stations",
    des: "Loading stations are equipped with semiautomated equipment, such as palletizing systems or conveyors, to assist operators in loading goods onto trucks.",
    background: "var(--footerbackground)",
  },

  {
    id: 3,
    heading: "Assistive Technologies",
    des: "Tools such as forklifts, pallet jacks, or other material handling equipment may be equipped with automation features to assist operators in transporting and loading goods.",
    background: "var(--orange)",
  },

  {
    id: 4,
    heading: "Human-Machine Interface ",
    des: "An interface that allows operators to interact with and control the semiautomated loading equipment, providing flexibility and oversight.",
    background: "var(--footerbackground)",
  },

  {
    id: 5,
    heading: "Automated Guided Vehicles ",
    des: "(AGVs) autonomously transport goods from production to loading docks, eliminating the need for human intervention in the process.",
    background: "var(--orange)",
  },

  {
    id: 6,
    heading: "Robotic Palletizing Systems",
    des: "Utilizing precision and speed, robots efficiently palletize goods, placing them onto pallets with automated precision and accuracy.",
    background: "var(--footerbackground)",
  },

  {
    id: 7,
    heading: "Automated Loading Arms",
    des: "Robotic arms equipped with suction or gripping mechanisms load goods directly onto trucks, eliminating the need for manual intervention.",
    background: "var(--orange)",
  },

  {
    id: 8,
    heading: "Sensor Technologies",
    des:"Vision systems, LiDAR, and other sensors ensure precise positioning and prevent collisions during the loading process.",
    background: "var(--footerbackground)",
  },

  {
    id: 9,
    heading: "Control System",
    des: "Unified control system oversees automated loading, managing goods movement, robotics, and communication with interconnected systems.",
    background: "var(--orange)",
  }
];

export const truck_benefits = [
  {
    id: 1,
    heading: "Streamlined Loading Processes",
    des: "Aim to minimize downtime and enhance operational efficiency.",
  },

  {
    id: 2,
    heading: "Maximized Space Utilization",
    des: "Designs optimize truck space for efficient cargo loading.",
  },

  {
    id: 3,
    heading: "Improved Loading Accuracy",
    des: "Ensures precise loading, minimizing errors and product damage.",
  },

  {
    id: 4,
    heading: "Enhanced Safety Measures",
    des: "Implements safety protocols to safeguard workers during loading.",
  },

  {
    id: 5,
    heading: "Reduced Manual Labor",
    des: "Automation diminishes reliance on manual labor, improving efficiency.",
  },

  {
    id: 6,
    heading: "Faster Loading Times",
    des: "Efficient processes lead to quicker truck turnaround and timely deliveries.",
  },

  {
    id: 7,
    heading: "Adaptability to Cargo Types",
    des: "Versatile solutions handle palletized, loose, and diverse cargo types.",
  },

  {
    id: 8,
    heading: "Real-time Monitoring",
    des: "Incorporates monitoring for visibility and control during loading processes.",
  },

  {
    id: 9,
    heading: "Integration with WMS",
    des: "Seamless connection with warehouse management systems for optimized coordination.",
  },

  {
    id: 10,
    heading: "Reduced Damage to Goods",
    des: "Automated loading minimizes mishandling, reducing the risk of damage.",
  },

  {
    id: 11,
    heading: "Compliance with Regulations",
    des: "Ensures loaded trucks comply with weight restrictions and safety regulations. ",
  },
];
