import React from "react";
import home_side1 from "../images/home-side1.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export const HomeAbout = () => {
  return (
    <>
      <section className="section_padding partnerSec1">
        <div className="container pt-lg-5 pt-lg-3">
          <div className="row justify-content-between align-items-center">
            <div className="col col-md-12 col-sm-12 col-lg-7 mb-5 pe-2">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                We Are
                </h1>
                <p
                  className="card-paragraph mt-3"
                  style={{ textAlign: "justify" }}
                >
                  Airi-Sense is a dynamic and emerging company based in the
                  heart of India, dedicated to revolutionizing the future of
                  material handling, material management, material transfer, and
                  Robotic Automation including Picking, Packing & Palletizing.
                </p>
                <p
                  className="card-paragraph mt-3"
                  style={{ textAlign: "justify" }}
                >
                  Our unwavering commitment is to develop innovative solutions
                  that seamlessly adapt to the ever-evolving dynamics of the
                  Indian Industry.
                </p>
                <p
                  className="card-paragraph mt-3"
                  style={{ textAlign: "justify" }}
                >
                  Airi-Sense provides data-driven robotic & automation solutions
                  for your current and future requirements, complemented by
                  dependable, modular concepts. We are dedicated to establishing
                  pioneering benchmarks in the field of material handling
                  automation, ensuring the provision of products and solutions
                  that are prepared for the future through partnerships with
                  innovative companies.
                </p>
                <p
                  className="card-paragraph mt-3"
                  style={{ textAlign: "justify" }}
                >
                  We're not merely envisioning the future of industrial
                  automation; we're actively shaping it.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5"> 
              <div className="about_us_img">
                <img src={home_side1} width="100%" className="ps-lg-3 ps-xl-3" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
