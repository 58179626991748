import React, { useState,useEffect } from "react";
import industries from "../images/industries/industries.webp";
import { Col, Container, Row } from "react-bootstrap";
import Pharma from "../images/industries/pharma.webp";
import Beverage from "../images/industries/beverage.webp";
import Chemical from "../images/industries/chemical.webp";
import Automotive from "../images/industries/automotive.webp";
import Botaling from "../images/industries/botaling.webp";
import Electronics from "../images/industries/electronics.webp";
import Milk from "../images/industries/milk.webp";
import Oil from "../images/industries/oil.webp";
import Rubber from "../images/industries/rubber.webp";
import Sugar from "../images/industries/sugar.webp";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import industriesMobileView from '../images/industriesMobileView.webp'
import './product.css';
export const Industries = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <=500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section id="our_pride">
        <div className="bnrImg">
          <img src={isMobileView?industriesMobileView:industries} width="100%" height="auto" />
        </div>
      </section>

      <section style={{ backgroundColor: "#f1f1f1" }}>
        <div className="mb-2 py-lg-5 container aboutOverview">
          <div className="row  justify-content-center ">
            <div className="col-lg-10  whatWeDo">
              <h1
                className="text-center py-2 fw-bold industries-section-hadding"  
                style={{ letterSpacing: "3px" }}
              >
                Industries
              </h1>
              <p className="text-right" style={{ textAlign: "justify" }}>
                Airi-Sense provides automation solutions aimed at improving
                manufacturing & material handling processes at all stages,
                regardless of type of industry. As experts in crafting bespoke,
                tailor-made, robotic, and software automation solutions, we
                collaborate closely with you to leverage technology and software
                expertise, creating solutions geared toward the future. Our
                mission is to aid businesses in their growth initiatives by
                identifying the ideal solutions for their unique operational
                challenges. Our consulting and system knowledge are built on a
                foundation of extensive experience. Working together, we
                constitute a unified team, serving as a complete provider of
                automation and Industry 4.0 solutions, delivering the
                competitive advantages of quality, innovation, and technological
                excellence for our clients.​ ​ ​
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section_padding partnerSec1 mt-4">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-7 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Automotive
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Robotic and automation solutions are integral to the
                  automotive industry, providing a wide range of benefits in
                  various aspects of manufacturing, assembly, and beyond.
                  Airi-Sense with its expertise can help you in determining
                  problems in your industry and also provide reliable, scalable
                  & futuristic solutions going beyond your needs. As team
                  Airi-Sense we have diverse exposure in working with all
                  domains with-in an Automotive industry from Part preparation,
                  to assembly line, Body in White, Painting, Coating, Assembly
                  line and testing setups for OEM’s and Tier suppliers. Our
                  solutions aims on.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">
                      {" "}
                      Improving Manufacturing Efficiency
                    </p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">
                      {" "}
                      Improving Quality Control
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Cost Reduction:</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Safety</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Flexibility</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Data Analytics</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-5">
              <div className="about_us_img">
                <img
                  src={Automotive}
                  width="100%"
                  height="430px"
                  className="rounded-circle img-hover-effect border border-black border-2 shadow"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_padding partnerSec1">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-4 mb-5">
              <div className="about_us_img  ">
                <img
                  src={Pharma}
                  width="100%"
                  alt=""
                  height="430px"
                  className="rounded-circle img-zoom-out border border-black border-2 shadow"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Pharma Industry
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Providing robotic and automation solutions to the
                  pharmaceutical industry is indeed a promising venture,
                  especially in light of the industry's growing focus on
                  efficiency, safety, and compliance. At Airi-Sense, with our
                  extensive experience, are well-positioned to deliver
                  cutting-edge solutions that address the unique challenges
                  faced by pharmaceutical clients.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Airi-Sense's commitment is to offer compliant, customized, and
                  innovative solutions aligns with the pharmaceutical industry's
                  need for precision, quality, and regulatory adherence. By
                  working closely with clients and incorporating modern
                  concepts, Airi-Sense is poised to build solutions aiming for.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Regulatory Compliance</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">
                      {" "}
                      Quality and Productivity
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Customization</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Safety and Sterility</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">
                      {" "}
                      Validation & Traceability
                    </p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Data Security</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="aboutRoot"> 
        <section className="section_padding partnerSec1">
          <div className="container py-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
                <div className="whatWeDo">
                  <h1
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      letterSpacing: "3px",
                    }}
                    className="text-start text-white"
                  >
                    <span className="text-white"> Chemical </span>
                  </h1>
                  <p
                    className="card-paragraph mt-4 text-light"
                    style={{ textAlign: "justify" }}
                  >
                    Robotic and automation solutions to the chemical industry
                    requires a combination of technical expertise, regulatory
                    knowledge, and a commitment to safety and quality.
                    Airi-Sense offers compliant, customized, and innovative
                    solutions, to contribute to the advancement of chemical
                    industry in material handling & material management
                    processes.
                  </p>
                  <p style={{ textAlign: "justify" }} className="text-light">
                    Airi-Sense is committed to build futuristics modular
                    solutions which seamlessly integrates with customer’s
                    existing setups and delivers the beyond expected results:
                    Our solutions are built while focusing.
                  </p>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">Safety and Compliance</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white"> Quality Control</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">
                        Hazardous Environments
                      </p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white">
                        {" "}
                        Remote Monitoring and Control
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">Data Management</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white">
                        {" "}
                        Maintenance and Repairs
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 mb-5">
                <div className="about_us_img">
                  <img
                    src={Chemical}
                    width="100%"
                    alt=""
                    height="430px"
                    className="rounded-circle img-hover-effect border border-white border-2 shadow"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_padding partnerSec1">
          <div className="container py-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12 col-lg-4 mb-5">
                <div className="about_us_img">
                  <img
                    src={Beverage}
                    width="100%"
                    alt=""
                    height="430px"
                    className="rounded-circle img-zoom-out border border-white border-2 shadow"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
                <div className="whatWeDo">
                  <h1
                    style={{
                      fontWeight: "600",
                      letterSpacing: "3px",
                    }}
                    className="text-start text-white"
                  >
                    <span className="text-white"> Beverage </span>
                  </h1>
                  <p
                    className="card-paragraph mt-4 text-light"
                    style={{ textAlign: "justify" }}
                  >
                    Cutting-edge robotic and automation solutions has the
                    capability to redefine the landscape of the beverage
                    industry . Airisense innovative technology optimizes
                    production processes, offering unparalleled efficiency and
                    precision. Through state-of-the-art robotics, we streamline
                    bottling, packaging, and distribution, ensuring seamless
                    operations. With a focus on enhancing productivity, reducing
                    errors, and meeting high industry standards, we elevate your
                    beverage production to new heights. Experience the
                    advantages of agile machinery, real-time data insights, and
                    precise automation tailored to the specific needs of the
                    beverage sector. Join the league of industry leaders and
                    revolutionize your operations with Airisense's
                    transformative solutions.
                  </p>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">Boost Efficiency</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white"> Maximize Output</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">Quality Assurance</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white"> Cost Saving</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className=" ps-2 text-white">Real-time Control</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "var(--white)" }} />
                      </span>
                      <p className="ps-2 text-white"> Flexibility</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section_padding partnerSec1">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Rubber
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Embrace innovation through Robotic and Automation solutions
                  that reshapes the way the rubber industry operates. Airisense
                  delivers smart, intuitive automation designed exclusively for
                  rubber manufacturing. Boost efficiency, minimize errors, and
                  maximize productivity with our advanced robotics. Streamline
                  processes, from material handling to production, ensuring
                  precision and safety at every step. Experience the future of
                  rubber industry operations, supported by technology that
                  brings a competitive edge and elevates your manufacturing
                  prowess to new heights.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Increased Productivity</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Improved Precision</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Optimized Processes</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Cost Reduction</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Enhanced Safety</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Environment Friendly</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-5">
              <div className="about_us_img ">
                <img
                  src={Rubber}
                  width="100%"
                  alt=""
                  height="430px"
                  className="rounded-circle img-hover-effect border border-black border-2 shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section_padding partnerSec1">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-4 mb-5">
              <div className="about_us_img ">
                <img
                  src={Milk}
                  width="100%"
                  alt=""
                  height="430px"
                  className="rounded-circle img-zoom-out border border-black border-2 shadow"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Milk & Dairy Products
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Revolutionize your dairy production with Airisense's
                  state-of-the-art robotic and automation solutions. Our
                  technology optimizes milk processing, packaging, and
                  distribution, ensuring precision and efficiency at every
                  stage. With seamless integration, these solutions enhance
                  productivity and minimize errors, meeting the industry's
                  demands. Gain real-time insights, ensure product quality, and
                  streamline operations. Empower your dairy facility with
                  advanced automation, ensuring high-quality products, reduced
                  waste, and a competitive edge in the market.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Precision Enhancement</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Instant Data Insights</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Quality Maintenance</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">
                      {" "}
                      Operational Streamlining
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black"> Market Leadership</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Efficiency Amplification</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="aboutRoot">
        <section className="section_padding partnerSec1">
          <div className="container py-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
                <div className="whatWeDo">
                  <h1
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      letterSpacing: "3px",
                    }}
                    className="text-start"
                  >
                    <span className="text-white"> Sugar & Confectionery </span>
                  </h1>
                  <p
                    className="card-paragraph mt-4 text-light"
                    style={{ textAlign: "justify" }}
                  >
                    Airisense introduces avant-garde robotics and automation
                    solutions tailored explicitly for the sugar and
                    confectionery sector. Our technology redefines production
                    methodologies, optimizing handling, packaging, and
                    processing, setting a new standard for operational
                    excellence. Our innovative robotics and automation solutions
                    designed exclusively for the sugar and confectionery
                    industry. Our technology revolutionizes production methods,
                    enhancing handling, packaging, and processing to establish a
                    new benchmark for operational superiority. This tailored
                    approach ensures efficiency and precision, empowering
                    manufacturers to meet industry demands effectively.
                  </p>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">Hygiene Compliance:</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white">
                        Customization Capability:
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">Cost Savings</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white"> Minimized Downtime</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">
                        {" "}
                        Resource Optimization
                      </p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white">Data Insights</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 mb-5">
                <div className="about_us_img">
                  <img
                    src={Sugar}
                    width="100%"
                    alt=""
                    height="430px"
                    className="rounded-circle img-hover-effect border border-white border-2 shadow"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_padding partnerSec1">
          <div className="container py-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12 col-lg-4 mb-5">
                <div className="about_us_img">
                  <img
                    src={Electronics}
                    width="100%"
                    alt=""
                    height="430px"
                    className="rounded-circle img-zoom-out border border-white border-2 shadow"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
                <div className="whatWeDo">
                  <h1
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      letterSpacing: "3px",
                    }}
                    className="text-start"
                  >
                    <span className="text-white"> Electronics </span>
                  </h1>
                  <p
                    className="card-paragraph mt-4 text-light"
                    style={{ textAlign: "justify" }}
                  >
                    The Robotic & Automation industry offers comprehensive
                    solutions to the electronics industry, ensuring seamless
                    operations. By leveraging JIT (Just-In-Time) practices,
                    Airisense optimizes the process through comprehensive
                    automation solutions . This results in reduced lead times,
                    cost efficiencies, and improved production reliability for
                    electronics manufacturers. Furthermore, it enhances
                    visibility and flexibility, enabling rapid response to
                    market demands and fluctuations in electronics production,
                    ultimately fostering competitiveness and innovation in the
                    electronics sector.
                  </p>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">Rapid Innovation</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white">Seamless Integration</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">Advanced Technology</p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white">Precision Engineering</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex  col-lg-6 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className=" ps-2 text-white">
                        {" "}
                        Enhanced Productivity
                      </p>
                    </div>
                    <div className="d-flex  col-lg-6 ps-lg-3 ">
                      <span className="h4">
                        <AiFillCheckCircle style={{ color: "white" }} />
                      </span>
                      <p className="ps-2 text-white">Agile Response</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section_padding partnerSec1">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Oil
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  The synergy between the Robotics & automotive industry and the
                  oil sector is pivotal. Offering vital solutions, Airisense
                  optimizes operations, curbs expenses, and amplifies
                  effectiveness. Ensuring punctual delivery of crucial
                  components, this integration guarantees streamlined oil
                  production and refining. Leveraging advanced technology and
                  efficient networks, we minimize delays, enhances resource
                  employment, and fortifies the intricate mechanics of the oil
                  industry. This collaborative alliance prioritizes precision
                  and operational fluidity, fostering an ecosystem where
                  efficiency thrives, costs are contained, and productivity is
                  elevated.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Elevated Productivity</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Advanced Technology</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Precision Engineering</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Rapid Innovation</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Agile Response</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Improved Reliability</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-4 mb-5">
              <div className="about_us_img ">
                <img
                  src={Oil}
                  width="100%"
                  alt=""
                  height="430px"
                  className="rounded-circle img-hover-effect border border-black border-2 shadow "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_padding partnerSec1">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-4 mb-5">
              <div className="about_us_img ">
                <img
                  src={Botaling}
                  width="100%"
                  alt=""
                  height="430px"
                  className="rounded-circle img-zoom-out border border-black border-2 shadow"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-7 col-lg-6 mb-5">
              <div className="whatWeDo">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start"
                >
                  Bottling
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Revolutionize the Bottling Industry with Advanced Robotics and
                  Automation Solutions. Discover how our technology streamlines
                  bottling processes, optimizing production, packaging, and
                  quality control. Our solutions ensure precision, efficiency,
                  and seamless operations, transforming the way bottling
                  facilities function. With automated systems designed
                  specifically for this sector, we provide a future-ready
                  approach that maximizes output, reduces errors, and meets the
                  unique demands of the bottling industry.
                </p>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Reduced Errors</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Optimal Output</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Cost Savings</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black"> Enhanced Productivity</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex  col-lg-6 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className=" ps-2 text-black">Simplified Processes</p>
                  </div>
                  <div className="d-flex  col-lg-6 ps-lg-3 ">
                    <span className="h4">
                      <AiFillCheckCircle style={{ color: "var(--orange)" }} />
                    </span>
                    <p className="ps-2 text-black">Efficiency Amplification</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
