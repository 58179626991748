import React from "react";

export default function Policy() {
  return (
    <>
      <div className="shadow" style={{background:"#fb8b23"}}>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="terms-header">
              <h2 className="py-2 text-white">Privacy Policy</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-3">
        <div className="row">
          <div className="col-10">
            <div className=" pt-4 terms-heading">
              <h4 className="text-start">Information We Collect</h4>
              <ol className="pt-3">
                <li className="term-list">
                  <span className="fs-6 fw-bold">Information You Provide:</span>{" "}
                  We may collect personal information that you voluntarily
                  provide to us when you
                </li>
                <ul className="pt-2" style={{ listStyleType: "disc" }}>
                  <li className="term-list">Register for an account. </li>
                  <li className="term-list">Fill out forms on our Website. </li>
                  <li className="term-list">
                    Contact us via email or other communication channels.
                  </li>
                  <li className="term-list">
                    Participate in surveys, contests, or promotions.
                  </li>
                  <li className="term-list">Make purchases on our Website.</li>
                </ul>
                <li className="term-list pt-2">
                  <span className="fs-6 fw-bold">
                    Automatically Collected Information:{" "}
                  </span>{" "}
                  When you visit our Website, we may automatically collect
                  certain information, such as your IP address, browser type,
                  operating system, and browsing behaviour through cookies and
                  similar tracking technologies.{" "}
                </li>
              </ol>
            </div>

            <div className=" pt-lg-3 terms-heading">
              <h4 className="text-start">How We Use Your Information</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  We may use your personal information for the following
                  purposes
                </li>
                <ol className="pt-3">
                  <li className="term-list">
                    To provide and maintain our services.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To improve our Website and customer experience.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To process transactions and send order notifications.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To respond to your inquiries, requests, or comments.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To send marketing and promotional materials (with your
                    consent).{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To conduct surveys, contests, and promotions (with your
                    consent).{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    To comply with legal obligations.{" "}
                  </li>
                </ol>
              </ul>
            </div>

            <div className=" pt-3 terms-heading">
              <h4 className="text-start">Disclosure of your Information</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  We may share your personal information with third parties in
                  the following circumstances
                </li>
                <ol className="pt-3">
                  <li className="term-list">
                    With service providers who assist us in operating our
                    Website and providing services.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    With our business partners, affiliates, or subsidiaries for
                    marketing or business purposes.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    With law enforcement or regulatory authorities when required
                    by law.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    In connection with a merger, acquisition, or sale of all or
                    part of our business.{" "}
                  </li>
                </ol>
              </ul>
            </div>

            <div className=" pt-3 terms-heading">
              <h4 className="text-start">Your Choices</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  You have the following rights regarding your personal
                  information
                </li>
                <ol className="pt-3">
                  <li className="term-list">
                    {" "}
                    Access: You can request access to the personal information
                    we hold about you.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    Correction: You can request corrections to any inaccurate or
                    incomplete personal information.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    Deletion: You can request the deletion of your personal
                    information under certain circumstances.{" "}
                  </li>
                  <li className="term-list">
                    {" "}
                    Opt-Out: You can opt out of receiving marketing
                    communications by following the instructions provided in
                    such communications.{" "}
                  </li>
                </ol>
              </ul>
            </div>
            <div className=" pt-3 terms-heading">
              <h4 className="text-start">Security</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <p className="term-list">
                  We take reasonable steps to protect your personal information
                  from unauthorized access, disclosure, or alteration. However,
                  no method of transmission over the Internet or electronic
                  storage is entirely secure, and we cannot guarantee absolute
                  security.
                </p>
              </ul>
            </div>
            <div className=" pt-3 terms-heading">
              <h4 className="text-start">Changes to this Privacy Policy</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <p className="term-list">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. We will notify you of any material changes
                  by posting the updated Privacy Policy on our Website.
                </p>
              </ul>
            </div>
            <div className=" pt-3 terms-heading">
              <h4 className="text-start">Contact Us</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <p className="term-list">
                  If you have any questions or concerns about this Privacy
                  Policy or our data practices , please contact us at{" "}
                  <a
                    href="mailto:info.automate@airi-sense.co.in"
                    className="text-decoration-none text-warning"
                  >
                    automate@airi-sense.co.in
                  </a>
                  .
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
