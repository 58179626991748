import React, { useState,useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import solutions from "../images/solutions.webp";
import Card from "react-bootstrap/Card";
import "./product.css";
import { solutionCard } from "./SolutionCard";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import solutionMobileView from '../images/solutionMobileView.webp'

export const Solutions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <=500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section id="our_pride">
        <div className="bnrImg">
          <img src={isMobileView?solutionMobileView:solutions} width="100%" height="auto" />
        </div>
      </section>
      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className=" container ">
          <div className="row  justify-content-center py-5 ">
            <div className="col-lg-12 whatWeDo solution_WhatWeDo">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
               fontSize:"55px"
                }}
                className="text-center" 
              >
                Modular Solutions
              </h1>
              <p
                className="text-white pt-3"
                style={{ textAlign: "justify", fontSize: "25px" }}
              >
                Modular solutions provide a wide range of benefits across
                different industries and applications. Here are 12 key
                advantages of modular solutions
              </p>
            </div>
            <div className="col-lg-12 py-lg-3">
              <div className="row">
                <div className="col-lg-6 pe-5 pt-4 ">
                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Scalability:</h4>
                  </div>

                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Easily expand or reduce the system to accommodate changing
                    requirements
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Customization:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    {" "}
                    Tailor the system to specific needs and preferences.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Quick Deployment:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    {" "}
                    Faster implementation due to independent module installation
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Cost Efficiency: </h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Reduce downtime and achieve cost savings over time.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Adaptability:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    A realistic approach, ensuring on-time delivery every time
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Reduced Risk:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Isolated module failures don't disrupt the entire system,
                    minimizing critical failures.
                  </p>
                </div>
                <div className="col-lg-6 ps-lg-4 pt-3">
                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Interoperability:</h4>
                  </div>

                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Work with existing systems and software, reducing the need
                    for a complete overhaul.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Testing and Quality Control:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    {" "}
                    Rigorous testing and quality control for enhanced
                    reliability.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Future-Proofing:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    {" "}
                    Upgrade or replace modules to keep the system up-to-date.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Maintenance Ease: </h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Simplified troubleshooting and maintenance with isolated
                    components.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Environmental Impact:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Reduce waste and carbon footprint by targeted upgrades.
                  </p>

                  <div className="d-flex">
                    <span className="h4 me-2">
                      <AiFillCheckCircle style={{ color: "var(--white)" }} />
                    </span>
                    <h4 className="text-white">Versatility:</h4>
                  </div>
                  <p
                    className="fs-5 ms-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Applicable to various industries, including manufacturing,
                    IT, healthcare, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" py-lg-5">
        <div className="row justify-content-center mx-3">
          {solutionCard.map((card, index) => (
            <div className="col-lg-3 mx-2">
              <Card
                className={`me-2 border border-light border-5 shadow rounded-3 cardhover ${isHovered ? "hovered" : ""}`}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                style={{ height: "au" }}
              >
                <Card.Img
                  variant="top"
                  src={card.img}
                  alt={card.title}
                  style={{ height: "250px" }}
                />
                <Card.Body>
                  <Link to={card.link} className="text-black  " style={{textDecoration:"none"}}><Card.Title className="pt-2 fw-bold">{card.title}</Card.Title></Link>
                  <ListGroup>
                    {card.list.map((item, itemIndex) => (
                      <ListGroup.Item className="text-start list-group-item">
                        {item}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </div>
     
    </div>
  );
};
