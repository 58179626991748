import React from "react";
// import footerlogo from "../images/footerlogo.webp";
import "../component/footer.css";
import { FaLinkedinIn } from "react-icons/fa";
import { FaOutdent } from "react-icons/fa";
import { ImBlog } from "react-icons/im";
import { GiStairsGoal } from "react-icons/gi";
import { HiDocumentCheck, HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import {
  BiLogoGmail,
  BiMailSend,
  BiMobile,
  BiSolidContact,
} from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { LuDot } from "react-icons/lu";
import { AiFillYoutube } from "react-icons/ai";
import whitetext from "../images/whitetext.png";
import { Link } from "react-router-dom";
import "./footer.css";

export const Footer = () => {
  const phoneNumber = "98270 67846"; // Replace with the desired phone number

  const handlePhoneCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = () => {
    const email = "automate@airi-sense.co.in"; 
    window.location.href = `mailto:${email}`;
  };

  const openFacebookPage = () => {
    const facebookPageUrl = "https://www.facebook.com/yourpage";

    window.open(facebookPageUrl, "_blank");
  };

  const openLinkedInProfile = () => {
    const linkedInProfileUrl = "https://www.linkedin.com/company/airisense-technologies";
    window.open(linkedInProfileUrl, "_blank");
  };

  const openYouTubeChannel = () => {
    const youTubeChannelUrl = "https://www.youtube.com/@AiriSenseTechnologies";
    window.open(youTubeChannelUrl, "_blank");
  };

  return (
    <>
      <footer className="footer-section">
        <div className=" container-fluid w-100">
          <div className="row justify-content-center pt-4">
            <div className="col-lg-3 col-md-12 col-sm-12 pt-3 px-3  text-start ">
              <Link to="/" className="text-start">
                {" "}
                <img src={whitetext} className="w-75" alt="logo_pic" />{" "}
              </Link>
              <p
                className="nav-links py-0 my-1"
                style={{
                  textAlign: "justify",
                  letterSpacing: "2px",
                  fontSize: "smaller",
                }}
              >
                DIGITAL SOLUTION <LuDot  className="fs-5"/> SMART SOLUTION
              </p>
              <div class="d-flex  justify-content-start">
                <li onClick={openLinkedInProfile} className="mb-3  nav-link">
                  <FaLinkedinIn className="me-3 fs-4  " role="button" tabindex="0" />
                  LinkedIn
                </li>
                {/* <li onClick={openYouTubeChannel} className="mb-3  ms-5 nav-link">
                  {" "}
                  <AiFillYoutube className="me-3 fs-4" />
                  Youtube
                </li> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 px-3 ms-lg-5 pt-lg-3 text-start">
              <h4 className="text-start nav-links">Useful Links</h4>
              <ul
                className="list-group py-3 "
                style={{ listStyleType: "none" }}
              >
                <Link to="/about" className="mb-3  nav-link">
                  {" "}
                  <li>
                    <FaOutdent className="me-3 fs-4" />
                    About-us
                  </li>
                </Link>
                {/* <Link to="/blogs" className=" mb-3 nav-link">
                  {" "}
                  <li>
                    <ImBlog className="me-3 fs-4"  /> Blogs
                  </li>
                </Link> */}

                <Link to="/careers" className=" mb-3 nav-link">
                  <li>
                    {" "}
                    <GiStairsGoal className="me-3 fs-4"  />
                    Career
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 text-left px-3  pt-lg-3 ">
              <h4 className="text-start nav-links ">Policies</h4>
              <ul
                className="list-group py-3 "
                style={{ listStyleType: "none" }}
              >
                {" "}
                <Link to="/policies" className=" mb-3 nav-link">
                  <li>
                    {" "}
                    <HiOutlineClipboardDocumentCheck className="me-3 fs-4" />
                    Privacy Policy
                  </li>
                </Link>
                
                <Link to="/conditions" className=" mb-3 nav-link">
                  <li>
                    {" "}
                    <HiOutlineClipboardDocumentCheck className="me-3 fs-4" />
                    Terms & Conditions
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 text-start px-3 pt-lg-3 ">
              <h4 className="text-start nav-links ">Connect</h4>
              <ul className="list-group " style={{ listStyleType: "none" }}>
                <li
                  className=" mb-3  nav-link  text-start"
                  onClick={handleEmailClick}
                >
                  <div class="d-flex  text-start">
                    <BiLogoGmail className="me-3 fs-5" />
                    <span>automate@airi-sense.co.in</span>
                  </div>
                </li>

                <Link to="/" className=" mb-3 nav-link  text-start">
                  <li onClick={handlePhoneCallClick}>
                    <a href="tel:9667940021" className="nav-link">
                      <BiMobile className="me-3 fs-4 " />
                      9667940021{" "}
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
