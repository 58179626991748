import React, { useState, useEffect } from "react";
import conveying_bnr from "../../images/product/product_details/conveyingBnr.webp";
import conveying from "../../images/product/product_details/conveying.webp";
import icon1 from "../../images/product/icons/turn-table.png";
import icon2 from "../../images/product/icons/cross-trans.png";
import icon3 from "../../images/product/icons/stacker.png";
import icon4 from "../../images/product/icons/dimention.png";
import catelog1 from "../../pdfCattalog/TurnTables - Tote,bCase & Pallet.pdf";
import catelog2 from "../../pdfCattalog/CrossTransfer - Tote, Case & Pallet.pdf";
import catelog3 from "../../pdfCattalog/PalletStacker & Destacker.pdf";
import catelog4 from "../../pdfCattalog/Dimensioning, Weighing & Scanning.pdf";
import { FaDownload } from "react-icons/fa6";
import conveyingdetailMobileView from "../../images/product/product_details/cinveyingdetailMobileView.webp";
import DilogueBox from "../../component/DilogueBox";

export const ConveyingDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );

  // Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? conveyingdetailMobileView : conveying_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  Conveying Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Discover Airisense's diverse range of conveyor system
                  accessories designed to seamlessly integrate with various
                  conveyor types. These purpose-built additions play a crucial
                  role in ensuring a smooth transition of products between
                  conveyors.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Whether optimizing efficiency, streamlining operations, or
                  ensuring compatibility, our meticulously crafted accessory
                  line meets diverse conveyor needs. From enhancing performance
                  to facilitating seamless transitions, Airisense delivers
                  comprehensive solutions for efficient conveyor systems.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Trust in Airisense's commitment to excellence as we provide
                  solutions that go beyond the conveyors themselves, offering a
                  complete suite of accessories to optimize your material
                  handling processes and elevate the overall performance of your
                  conveyor system.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-lg-5 ">
              <div className="about_us_img ">
                <img
                  src={conveying}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                }}
                className="text-center text-white display-4 "
              >
                Enhance Transitions with Conveyor Accessories
              </h1>
            </div>

            <div className="py-3">
              <div className="row ">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Turn Tables - Tote, Case & Pallet{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Revolutionizing pallet handling, Turntables smoothly rotate
                    loads within a 360-degree range, driven by precision roller
                    chain technology. Ensuring efficiency and reliability, the
                    robust bearing securely supports the load for optimal
                    performance in your pallet handling process.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Cross Transfer - Tote, Case & Pallet{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Cross Transfer for totes provides limitless accumulation,
                    seamlessly integrating into multi-process handling systems.
                    Featuring lift-and-carry arms, these versatile transports
                    efficiently move materials laterally with customizable
                    options for various lengths, enhancing material handling
                    efficiency.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Pallet Stacker & Destacker{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Uncover the reliable Pallet Stacker & Destacker, a versatile
                    solution automating pallet block stacking and destacking
                    with separator pallets. Engineered for low to medium
                    performance, it efficiently handles two-tier pallets,
                    enhancing productivity in diverse industrial settings.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Dimensioning, Weighing & Scanning{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Automate dimension and weight capture with DWS Systems for
                    precise data acquisition in final sorting, boosting
                    industrial automation profitability. Enhance shipping
                    accuracy and customer services, optimizing order fulfillment
                    efficiency for streamlined logistics precision.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog4)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          {/* Download modal */}
          <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
