import logo from "./logo.svg";
import "./App.css";
import { Header } from "./component/Header";
import { Home } from "./component/Home";
import { Route, Routes } from "react-router-dom";
import { HomeAbout } from "./component/HomeAbout";
import { About } from "./page/About";
import { Customer } from "./page/Customer";
import { PageNotFound } from "./page/PageNotFound";
import { Industries } from "./page/Industries";
import { Contact } from "./page/Contact";
import { Solutions } from "./page/Solutions";
import Blogs from "./page/Blogs";
import { Product } from "./page/Product";
import { Footer } from "./component/Footer";
import { Carrers } from "./page/Carrers";
import { SmartRoboticDetails } from "./page/ProductDetails/SmartRoboticDetails";
import { IIOT_AnalyticsDetails } from "./page/ProductDetails/IIOT_AnalyticsDetails";
import { MaterialManageDetails } from "./page/ProductDetails/MaterialManageDetails";
import { SmartConveyingDetails } from "./page/ProductDetails/SmartConveyingDetails";
import { ConveyingDetails } from "./page/ProductDetails/ConveyingDetails";
import { TruckLoadingDetails } from "./page/ProductDetails/TruckLoadingDetails";
import { InlineStorageDetails } from "./page/SolutionDetails/InlineStorageDetails";
import { PackagingDetails } from "./page/SolutionDetails/PackagingDetails";
import { PalletizingDetails } from "./page/SolutionDetails/PalletizingDetails";
import { TrackTraceDetails } from "./page/SolutionDetails/TrackTraceDetails";
import { TruckDetails } from "./page/SolutionDetails/TruckDetails";
import { WeightRejectDetails } from "./page/SolutionDetails/WeightRejectDetails";
import CurrentOpening from "./page/CurrentOpening";
import Policy from "./page/Policy";
import Conditions from "./page/Conditions";


function App() {
  return (
    <div>
      <Header />
   
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/homeabout" element={<HomeAbout />} />
        <Route path="/about" element={<About />}></Route>
        <Route path="/customer" element={<Customer />}>
          {" "}
        </Route>
        <Route path="/industries" element={<Industries />}>
          {" "}
        </Route>
        <Route path="/contact" element={<Contact />} />
        <Route path="/policies" element={<Policy />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/careers/current-openings" element={<CurrentOpening />} />

        <Route path="/product" element={<Product />} />
        <Route path="/product/smart-robotic-details" element={<SmartRoboticDetails/>}></Route>
        <Route path="/product/truck-loading-details" element={<TruckLoadingDetails/>}></Route>
        <Route path="/product/iiot-analytics-details" element={<IIOT_AnalyticsDetails/>}></Route>
        <Route path="/product/material-mangement-details" element={<MaterialManageDetails/>}></Route>
        <Route path="/product/smart-conveying-details" element={<SmartConveyingDetails/>}></Route>
        <Route path="/product/conveying-details" element={<ConveyingDetails/>}></Route>

        <Route path="/solutions" element={<Solutions />} />
        <Route path="/solutions/InlineStorageDetails" element={<InlineStorageDetails/>}></Route>
        <Route path="/solutions/PackagingDetails" element={<PackagingDetails />}></Route>
        <Route path="/solutions/PalletizingDetails" element={< PalletizingDetails/>}></Route>
        <Route path="/solutions/TrackTraceDetails" element={< TrackTraceDetails/>}></Route>
        <Route path="/solutions/TruckDetails" element={< TruckDetails/>}></Route>
        <Route path="/solutions/WeightRejectDetails" element={<WeightRejectDetails/>}></Route>
        <Route path="/careers" element={<Carrers />} />
        <Route path="/*" element={<PageNotFound />}>
          {" "}
        </Route>


        {/* <Route path="/product" element={<Product />}>
          <Route path="smartRobotic"  lazy={() => import("./page/SmartRobotic")}  />
          <Route path="b"   lazy={() => import("./page/SmartRobotic")} />
        </Route> */}
        
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
