export const inline_trends = [
  {
    id: 1,
    heading: "Smart and Automated Systems",
    subhead: "IoT Integration:",
    des: "Inline storage solutions are incorporating Internet of Things (IoT) technologies for real-time monitoring, predictive maintenance, and data-driven decision-making.",
  },

  {
    id: 2,
    heading: "Robotics and Automation",
    subhead: "Collaborative Robotics:",
    des: "The integration of collaborative robots (cobots) with inline storage systems to enhance automation, increase efficiency, and streamline material handling processes.",
  },

  {
    id: 3,
    heading: "Modular and Scalable Solutions",
    subhead: "Adaptability:",
    des: "Inline storage systems are becoming more modular and scalable to accommodate changes in production requirements and support flexible manufacturing setups.",
  },

  {
    id: 4,
    heading: "Data Analytics for Optimization",
    subhead: "Big Data Analytics:",
    des: "Utilizing big data analytics to analyze patterns, optimize storage configurations, and enhance overall system performance and efficiency.",
  },

  {
    id: 5,
    heading: "Integration with Industry 4.0",
    subhead: "Industry 4.0 Principles:",
    des: "Inline storage solutions align with Industry 4.0 concepts, integrating connectivity, data exchange, and smart technologies for a more interconnected and intelligent manufacturing environment.",
  },

  {
    id: 6,
    heading: "Augmented Reality (AR) for Maintenance",
    subhead: "AR Maintenance Assistance:",
    des: "Use of augmented reality for maintenance purposes, providing technicians with real-time information, instructions, and diagnostics to optimize system performance.",
  },

  {
    id: 7,
    heading: "Focus on Sustainability",
    subhead: "Green Storage Solutions:",
    des: "Incorporating sustainable practices in inline storage, including energy-efficient systems and eco-friendly materials, to align with growing environmental consciousness in manufacturing.",
  },

  {
    id: 8,
    heading: "Blockchain for Traceability",
    subhead: "Enhanced Traceability:",
    des: "Exploring blockchain technology to enhance traceability and transparency in the storage and movement of materials within the production line.",
  },

  {
    id: 9,
    heading: "Voice-Activated Systems",
    subhead: "Voice-Activated Commands:",
    des: "Implementation of voice-activated systems to facilitate hands-free operation, improving operator efficiency and reducing the need for manual inputs",
  },

  {
    id: 10,
    heading: "Enhanced Security Measures",
    subhead: "Cybersecurity Features:",
    des: "Integration of advanced cybersecurity measures to protect data and ensure the secure operation of inline storage systems, especially as they become more connected",
  },

  {
    id: 11,
    heading: "Real-time Inventory Optimization",
    subhead: "Dynamic Inventory Management:",
    des: "Real-time optimization of inventory levels using advanced algorithms and machine learning to reduce excess stock and enhance production efficiency.",
  },

  {
    id: 12,
    heading: "Customization for Industry Specifics",
    subhead: "Industry-Specific Solutions:",
    des: "Developing inline storage solutions tailored to the specific needs and requirements of different manufacturing industries, ensuring optimal performance and functionality.",
  },

  {
    id: 13,
    heading: "Human-Machine Collaboration",
    subhead: "Human-Centric Design:",
    des: "Designing inline storage systems with a focus on human-machine collaboration, ensuring that operators can easily interact with and manage the system.",
  },

  {
    id: 14,
    heading: "Real-time Communication",
    subhead: "Communication Protocols:",
    des: "Utilizing advanced communication protocols to enable real-time data exchange between inline storage systems, production equipment, and other elements of the manufacturing process.",
  },

  {
    id: 15,
    heading: "Augmented Intelligence (AI)",
    subhead: "AI-Driven Decision Support:",
    des: "Integration of augmented intelligence to provide decision support for optimizing storage configurations, predicting maintenance needs, and improving overall system efficiency.",
  },

  {
    id: 16,
    heading: "Mobile Access and Control",
    subhead: "Mobile Connectivity:",
    des: "Allowing operators to access and control inline storage systems using mobile devices, providing flexibility and remote management capabilities.",
  },


];

export const inline_concept = [
  {
    id: 1,
    heading: "Strategic Placement",
    des: "Inline storage units are strategically placed at various points along the production line, ensuring that raw materials, components, or intermediate goods are easily accessible to operators as needed.",
    background: "var(--orange)",
  },

  {
    id: 2,
    heading: "Modularity and Flexibility",
    des: "The storage solutions are often modular and adaptable, allowing for easy reconfiguration to accommodate changes in production requirements, product lines, or process layouts.",
    background: "var(--footerbackground)",
  },

  {
    id: 3,
    heading: "Integration with Production Equipment",
    des: "Inline storage is seamlessly integrated with production machinery and workstations, reducing the need for extensive material handling and minimizing downtime.",
    background: "var(--orange)",
  },

  {
    id: 4,
    heading: "Real-time Inventory",
    des: "Inline storage solutions integrate real-time inventory tracking technologies, delivering accurate and current material availability information at every production station.",
    background: "var(--footerbackground)",
  },

  {
    id: 5,
    heading: "Quick Changeover Capability",
    des: "Inline storage facilitates quick changeovers between different products or production runs by ensuring that changeover materials are stored in close proximity to the relevant workstations.",
    background: "var(--orange)",
  },

  {
    id: 6,
    heading: "Lean Manufacturing Principles",
    des: "The concept aligns with lean manufacturing principles by minimizing waste, reducing excess inventory, and promoting a more streamlined and efficient production process.",
    background: "var(--footerbackground)",
  },

  {
    id: 7,
    heading: "Integration with Automation",
    des: "In advanced manufacturing environments, inline storage solutions may integrate with automation systems, allowing for autonomous retrieval and replenishment of materials.",
    background: "var(--orange)",
  },

  {
    id: 8,
    heading: "Just-in-Time Support",
    des: "Inline storage supports JIT manufacturing principles by ensuring that materials are available precisely when needed, reducing excess inventory and associated carrying costs.",
    background: "var(--footerbackground)",
  },

  {
    id: 9,
    heading: "Reduction of Material Handling",
    des: "Material handling is minimized as products move seamlessly between storage units and production stations, reducing the risk of errors and increasing operational efficiency.",
    background: "var(--orange)",
  },

];

export const inline_benefits = [
  {
    id: 1,
    heading: "Reduced Storage Costs",
    des: "Inline storage cuts need for separate, costly storage spaces.",
  },

  {
    id: 2,
    heading: "Minimized Handling Errors",
    des: "Integrated storage reduces errors, ensuring higher product quality.",
  },

  {
    id: 3,
    heading: "Increased Productivity",
    des: "Proximity to tools speeds up material handling, boosting productivity.",
  },

  {
    id: 4,
    heading: "Enhanced Visibility and Organization",
    des: "Visual management of materials for easy identification and organization.",
  },

  {
    id: 5,
    heading: "Lean Manufacturing Support",
    des: "Aligns with lean principles, minimizing waste and optimizing processes.",
  },

  {
    id: 6,
    heading: "Shorter Lead Times",
    des: "Quick access to stored components for prompt customer fulfillment.",
  },

  {
    id: 7,
    heading: "Increased Flexibility",
    des: "Modular inline storage allows easy adaptation to changing production needs.",
  },

  {
    id: 8,
    heading: "Efficient Resource Utilization",
    des: "Optimizes time, labor, and space, enhancing overall manufacturing efficiency.",
  },

  {
    id: 9,
    heading: "Real-time Inventory Management",
    des: "Inline storage enables accurate tracking and real-time material management.",
  },

  {
    id: 10,
    heading: "Better Collaboration",
    des: "Inline storage fosters collaboration between operators and material replenishment teams.",
  },
];
