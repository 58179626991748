export const weighing_trends = [
  {
    id: 1,
    heading: "Integration with Industry 4.0",
    subhead: "Smart Manufacturing:",
    des: "Weighing and rejection systems are increasingly integrating with Industry 4.0 concepts, leveraging technologies such as IoT, data analytics, and connectivity for smarter and more adaptive manufacturing processes.",
  },

  {
    id: 2,
    heading: "Advanced Sensor Technologies",
    subhead: "Multi-Sensor Integration:",
    des: "Adoption of advanced sensor technologies, including vision systems, multi-sensor arrays, and 3D imaging, for more comprehensive and accurate quality inspections during weighing.",
  },

  {
    id: 3,
    heading: "Real-time Data Analytics",
    subhead: "Predictive Analytics:",
    des: "Implementation of real-time data analytics and machine learning algorithms to predict potential issues, optimize processes, and enhance overall system performance",
  },

  {
    id: 4,
    heading: "Blockchain for Traceability",
    subhead: "Enhanced Traceability:",
    des: "Exploration of blockchain technology to improve traceability and create an immutable record of weighing and rejection data, ensuring transparency and integrity in the supply chain",
  },

  {
    id: 5,
    heading: "Human-Machine Collaboration",
    subhead: "Collaborative Robotics:",
    des: "Integration of collaborative robots (cobots) to work alongside human operators in weighing and rejection processes, enhancing efficiency and flexibility in manufacturing",
  },

  {
    id: 6,
    heading: "Augmented Reality (AR) and Virtual Reality (VR)",
    subhead: "Training and Maintenance:",
    des: "Implementation of AR and VR technologies for training operators, performing maintenance tasks, and visualizing data in real-time for better decision-making",
  },

  {
    id: 7,
    heading: "Edge Computing",
    subhead: "Local Data Processing:",
    des: "Increased use of edge computing to process data locally on the shop floor, reducing latency and enabling faster decision-making in real-time weighing and rejection scenarios.",
  },

  {
    id: 8,
    heading: "Remote Monitoring and Control",
    subhead: "Cloud Connectivity:",
    des: "Adoption of cloud-based platforms for remote monitoring, control, and analysis of weighing and rejection systems, allowing manufacturers to access data from anywhere.",
  },

  {
    id: 9,
    heading: "Flexible and Modular Systems",
    subhead: "Scalability and Adaptability:",
    des: "Development of flexible and modular weighing and rejection systems that can easily adapt to changes in production requirements, product types, and throughput demands.",
  },

  {
    id: 10,
    heading: "Adoption of Robotics",
    sunhead: "Robotic Weighing Systems:",
    des: "Increasing use of robotics in weighing processes, enabling automated handling and positioning of products for more precise and efficient weighing.",
  },

  {
    id: 11,
    heading: "Enhanced User Interfaces",
    subhead: "Intuitive HMI:",
    des: ": Integration of advanced and user-friendly human-machine interfaces (HMIs) with features such as touchscreens, augmented reality displays, and customizable dashboards for operators.",
  },

  {
    id: 12,
    heading: "Predictive Maintenance",
    subhead: "Condition Monitoring:",
    des: ": Implementation of predictive maintenance strategies using IoT sensors to monitor the condition of components in weighing systems, preventing unexpected downtime.",
  },

  {
    id: 13,
    heading: "Cybersecurity Measures",
    sunhead: "Secure Data Handling:",
    des: "Heightened focus on cybersecurity measures to protect sensitive weighing data, ensuring the integrity and confidentiality of information.",
  },

  {
    id: 14,
    heading: "Multi-Stage Quality Checks",
    sunhead: "Comprehensive Quality Control:",
    des: "Integration of multi-stage quality checks within the weighing and rejection process, incorporating additional parameters beyond weight for a more thorough assessment of product quality.",
  },

  {
    id: 15,
    heading: "Standardization and Interoperability",
    sunhead: "Interconnected Systems:",
    des: "Emphasis on standardization and interoperability, allowing weighing and rejection systems to seamlessly integrate with other manufacturing systems and technologies.",
  },

  {
    id: 16,
    heading: "Augmented Intelligence (AI)",
    sunhead: "AI-Assisted Decision-Making:",
    des: "Implementation of augmented intelligence to assist operators in decision-making processes, providing insights and recommendations based on historical data and real-time analytics.",
  },


];

export const weighing_concept = [
  {
    id: 1,
    heading: "Weighing Equipment",
    des1: "Utilizes precision weighing scales, load cells, or other measurement devices to accurately determine the weight of products or components.",
    des2:"Can be integrated into various stages of the production line where weighing is critical.",
    background: "var(--orange)",
  },

  {
    id: 2,
    heading: "Automated Conveyor Systems",
    des1: "Transports products or components through the weighing station in a controlled and systematic manner.",
    des2:"Maintains uninterrupted item flow for weighing and rejection, ensuring a seamless and efficient production process.",
    background: "var(--footerbackground)",
  },

  {
    id: 3,
    heading: "Data-Traceability & Sensor",
    des1: "Efficiently records and logs weight data for each product, ensuring traceability and quality control compliance.",
    des2:"Integrates advanced sensors for real-time product detection and accurate decision-making in operations.",
    background: "var(--orange)",
  },

  {
    id: 4,
    heading: "Control Systems",
    des1: "Centralized control systems manage the overall operation of the weighing and rejection process.",
    des2:"Controls the activation of rejection mechanisms based on predefined criteria.",
    background: "var(--footerbackground)",
  },

  {
    id: 5,
    heading: "Rejection Mechanisms",
    des1: "Implements mechanisms to divert or reject items that do not meet the specified weight criteria or quality standards.",
    des2:"Examples include pneumatic ejectors, diverters, or conveyor re-routing systems.",
    background: "var(--orange)",
  },

  {
    id: 6,
    heading: "Human-Machine Interface",
    des1: "Provides an interface for operators to monitor and control the weighing and rejection process.",
    des2:"Displays real-time data, alerts, and system status.",
    background: "var(--footerbackground)",
  }
];

export const weighing_benefits = [
  {
    id: 1,
    heading: "Accurate Weighing for Consistency",
    des: "Maintains precision and consistency in manufacturing.",
  },

  {
    id: 2,
    heading: "Traceability with Recordkeeping",
    des: "Records weight data for batches, valuable for audits and recalls.",
  },

  {
    id: 3,
    heading: "Regulatory Compliance Assurance",
    des: "Ensures adherence to industry standards and regulations.",
  },

  {
    id: 4,
    heading: "Automated Weighing Processes",
    des: "Increases efficiency, reduces reliance on manual labor.",
  },

  {
    id: 5,
    heading: "High Throughput with Automation",
    des: "Non-compliant items rejected, maintaining production efficiency.",
  },

  {
    id: 6,
    heading: "Cost Reduction through Waste Minimization",
    des: "Identifies and rejects non-compliant items, minimizing waste.",
  },

  {
    id: 7,
    heading: "Product Consistency and Uniformity",
    des: "Contributes to uniformity within batches, meeting quality expectations.",
  },

  {
    id: 8,
    heading: "Defect Identification",
    des: "Early detection of underweight or overweight items for quality assurance.",
  },

  {
    id: 9,
    heading: "Risk Mitigation",
    des: "Helps prevent overfilling or underfilling, reducing compliance risks.",
  },

  {
    id: 10,
    heading: "Real-time Monitoring",
    des: "Provides real-time monitoring for issue identification, reducing downtime.",
  },
];
