import React, { useEffect, useState } from "react";
import cureentOpeningBnr from "../images/career/current-open-bnr.webp";
import cureentMobileViewBnr from "../images/career/currentMobileView.webp";
import { IoArrowForwardOutline } from "react-icons/io5";
export default function CurrentOpening() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <section id="our_pride">
        <div className="bnrImg">
          <img
            src={isMobileView ? cureentMobileViewBnr : cureentOpeningBnr}
            width="100%"
            height="auto"
          />
        </div>
      </section>

      <div className="row justify-content-center py-5 px-5 ">
        <div className="col-7 shadow">
          <div className="px-5 py-4">
            <h5>
              Currently, there are no openings, We'll keep your CV for
              consideration when positions become available. Please Mail your CV{" "}
              <a
                href="mailto:automate@airi-sense.co.in"
                className=" text-decoration-none fs-4 text-capitalize "
              >
                <span className="" style={{ color: "var(--orange)" }}>
                  <IoArrowForwardOutline /> automate@airi-sense.co.in
                </span>
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
