export const trace_trends = [
  {
    id: 1,
    heading: "Blockchain Integration",
    subhead: "Secure and Transparent Transactions:",
    des: "Blockchain technology is increasingly being explored for enhancing the security and transparency of track and trace data. It provides a decentralized and tamper-resistant ledger for secure transactions and data sharing across the supply chain.",
  },

  {
    id: 2,
    heading: "IoT and Sensor Integration",
    subhead: "Real-time Monitoring :",
    des: " The integration of Internet of Things (IoT) devices and sensors allows for real-time monitoring of product conditions during transportation and storage, ensuring compliance with specified environmental parameters.",
  },

  {
    id: 3,
    heading: "Edge Computing",
    subhead: "Local Data Processing :",
    des: "Edge computing is being utilized to process data locally at the source (e.g., on manufacturing equipment or at distribution centers), reducing latency and enabling quicker responses to deviations in the supply chain.",
  },

  {
    id: 4,
    heading: "AI and Machine Learning",
    subhead: "Predictive Analytics :",
    des: "Artificial Intelligence (AI) and machine learning algorithms are employed for predictive analytics, helping manufacturers anticipate potential issues in the supply chain and take proactive measures to prevent disruptions.",
  },

  {
    id: 5,
    heading: "Serialized Tracking",
    subhead: "Individual Product Tracking :",
    des: "Serialized tracking, which involves assigning unique identifiers to individual products, is becoming more prevalent. This allows for more granular and precise tracking of products throughout the supply chain.",
  },

  {
    id: 6,
    heading: "Mobile Track & Trace",
    subhead: "On-the-Go Access :",
    des: "Mobile applications and platforms for Track & Trace solutions are gaining popularity, providing stakeholders with on-the-go access to real-time tracking information using smartphones and tablets.",
  },

  {
    id: 7,
    heading: "Enhanced User Interfaces",
    subhead: "Intuitive Dashboards : ",
    des: "User interfaces are becoming more intuitive with interactive dashboards, providing users with a visually appealing and user-friendly experience for accessing and analyzing track and trace data.",
  },

  {
    id: 8,
    heading: "Hybrid Cloud Solutions",
    subhead: "Scalability and Flexibility :",
    des: "Hybrid cloud solutions, combining on-premises and cloud-based systems, offer scalability and flexibility. This allows manufacturers to scale their track and trace capabilities based on evolving business needs.",
  },

  {
    id: 9,
    heading: "Supply Chain Collaboration Platforms",
    subhead: "Interconnected Ecosystems:",
    des: "The development of supply chain collaboration platforms facilitates seamless communication and information exchange among different stakeholders, fostering a more interconnected and efficient supply chain ecosystem. ",
  },

  {
    id: 10,
    heading: "Focus on Sustainability",
    subhead: "Eco-friendly Packaging Tracking:",
    des: "Track & Trace solutions are increasingly being applied to monitor and trace eco-friendly packaging materials, supporting sustainability initiatives and ensuring compliance with environmental regulations. ",
  },

  {
    id: 11,
    heading: "Mobile RFID and NFC Technologies",
    subhead: "Contactless Tracking:",
    des: "The use of mobile RFID (Radio-Frequency Identification) and NFC (Near Field Communication) technologies is on the rise, allowing for contactless tracking and authentication of products. ",
  },

  {
    id: 12,
    heading: "Digital Twins",
    subhead: "Virtual Product Representations:",
    des: "Digital twin technology is being explored to create virtual representations of physical products throughout their lifecycle, offering a digital counterpart for monitoring and analysis.",
  },

  {
    id: 13,
    heading: "Regulatory Compliance Automation",
    subhead: "Automated Reporting:",
    des: "Track & Trace solutions are incorporating automation features to streamline the process of generating and submitting regulatory compliance reports, reducing the administrative burden on manufacturers. ",
  },

  {
    id: 14,
    heading: "Voice and Image Recognition",
    subhead: "Advanced Recognition Technologies: ",
    des: "Voice and image recognition technologies are being integrated into Track & Trace solutions for advanced authentication and verification of products and components. ",
  },
];

export const trace_concept = [
  {
    id: 1,
    heading: "Data-Collection & Serialization",
    des1: "Each product or component is assigned a unique identifier or serial number, often through barcodes, RFID tags, or other identification methods.",
    des2: "Throughout the manufacturing process, data is captured at various stages, including production, packaging, and distribution.",
    background: "var(--orange)",
  },

  {
    id: 2,
    heading: "End-to-End Visibility",
    des1: "The system provides end-to-end visibility, allowing manufacturers to trace the entire lifecycle of a product, from raw material sourcing to the end consumer.",
    des2: "Real-time tracking mechanisms monitor the location and status of products, enabling manufacturers to pinpoint their position.",
    background: "var(--footerbackground)",
  },

  {
    id: 3,
    heading: "Regulatory Compliance",
    des1: "Track & Trace solutions help manufacturers comply with industry regulations and standards by maintaining detailed documentation.",
    des2: "Creates a comprehensive audit trail, allowing for easy retrieval and verification of data during regulatory inspections.",
    background: "var(--orange)",
  },

  {
    id: 4,
    heading: "Quality Assurance",
    des1: "Facilitates early defect detection, empowering manufacturers to promptly address issues, preventing subpar products from reaching consumers.",
    des2: "Facilitates precise tracking of product batches, aiding in the identification and isolation of faulty batches.",
    background: "var(--footerbackground)",
  },

  {
    id: 5,
    heading: "Supply-Chain Optimization",
    des1: "Utilizes data collected from tracking systems for analytics, optimizing supply chain processes, and identifying areas for improvement.",
    des2: "Enhances demand forecasting by providing accurate information on product movement and consumption patterns.",
    background: "var(--orange)",
  },

  {
    id: 6,
    heading: "Counterfeit Prevention",
    des1: "Track & Trace solutions play a crucial role in preventing counterfeiting by allowing manufacturers and consumers to verify the authenticity of products.",
    des2: "Incorporates measures to detect and prevent tampering, ensuring the integrity of the product.",
    background: "var(--footerbackground)",
  },

  {
    id: 7,
    heading: "Efficient Recall Management",
    des1: "In the event of a recall, the system facilitates rapid identification and removal of affected products, minimizing the impact on consumers and the brand.",
    des2: "Enables efficient communication with consumers, distributors, and regulatory bodies during a recall.",
    background: "var(--orange)",
  },

  {
    id: 8,
    heading: "Customer Transparency",
    des1: "Fosters consumer trust through transparent manufacturing and supply chain insights, enabling product traceability and accountability.",
    des2: "Consumers may have access to product information through QR codes or online platforms.",
    background: "var(--footerbackground)",
  },

  {
    id: 9,
    heading: "Data Security",
    des1: "Deploys stringent data security to safeguard sensitive information, ensuring the confidentiality and integrity of tracking data for robust protection.",
    des2: "Restricts access to sensitive data, allowing only authorized personnel to view and modify information.",
    background: "var(--orange)",
  },

  {
    id: 10,
    heading: "Environmental Monitoring",
    des1: "Supervises environmental conditions in transit, guaranteeing adherence to specified temperature, humidity, and other parameters for product preservation.",
    des2: "Supervises storage, ensuring products meet defined parameters for quality during transportation.",
    background: "var(--footerbackground)",
  },

  {
    id: 11,
    heading: "Real-Time Reporting",
    des1: "Provides real-time monitoring capabilities, allowing manufacturers to respond quickly to deviations, disruptions, or issues in the supply chain.",
    des2: "Generates alerts and notifications for stakeholders in case of exceptions or irregularities.",
    background: "var(--orange)",
  },
  {
    id: 12,
    heading: "Integration with Technology",
    des1: "Integrates with Internet of Things (IoT) devices, sensors, and other advanced technologies to enable seamless data exchange and real-time communication.",
    des2: "Allows for the automation of tracking and tracing processes, reducing manual errors and enhancing overall efficiency.",
    background: "var(--footerbackground)",
  }
];

export const trace_benefits = [
  {
    id: 1,
    heading: "Traceability for Quality Control",
    des: "Tracks products from raw materials to finished goods for identification.",
  },

  {
    id: 2,
    heading: "Regulatory Compliance Support",
    des: "Documents manufacturing processes, ensuring compliance with industry standards.",
  },

  {
    id: 3,
    heading: "Supply Chain Visibility",
    des: "Offers end-to-end visibility, identifying bottlenecks and optimizing processes.",
  },

  {
    id: 4,
    heading: "Rapid Product Recall Identification",
    des: "Enables quick and precise identification of affected products during recalls.",
  },

  {
    id: 5,
    heading: "Counterfeit Prevention through Authentication",
    des: "Provides mechanisms to verify product authenticity, crucial in sensitive industries.",
  },

  {
    id: 6,
    heading: "Process Optimization with Data Analytics",
    des: "Collects and analyzes data to optimize workflows and enhance efficiency.",
  },

  {
    id: 7,
    heading: "Customer Confidence through Transparency",
    des: "Builds trust by offering transparency into sourcing, production, and distribution.",
  },

  {
    id: 8,
    heading: "Efficient Recall Management",
    des: "Allows targeted removal of affected products, reducing financial impact.",
  },

  {
    id: 9,
    heading: "Serialized Tracking for Unique Identification",
    des: "Assigns unique identifiers for precise tracking, crucial in specific industries.",
  },

  {
    id: 10,
    heading: "Efficient Logistics with Real-time Data",
    des: ": Facilitates streamlined logistics and inventory management with real-time product data.",
  },

  {
    id: 11,
    heading: "Cost Reduction through Quick Responses",
    des: "Minimizes financial impact by enabling quick responses to issues.",
  },

  {
    id: 12,
    heading: "Continuous Improvement with Data-Driven Insights",
    des: "Offers insights for identifying improvements and enhancing product quality.",
  },
];
