import product1 from '../images/product/product1.webp';
import product2 from '../images/product/product2.webp';
import product3 from '../images/product/product3.webp';
import product4 from '../images/product/product4.webp';
import product5 from '../images/product/product5.webp';
import product6 from '../images/product/product6.webp';

export const productCard = [
  {
    img: product1,
    link:'smart-robotic-details',
    title: "Smart Robotic Solutions",
    list: ["Robotic / Gantry Palletizing Cell","Robotic / Gantry Depalletizing Cell","Robotic / Gantry Pick & Place Cell","Robotic / Gantry Packaging Cell"]
  },
  {
    img: product2,
    link:'material-mangement-details',
    title: "Material Handling Solutions",
    list: ["In Line Storage Module", "Vertical Lift - Case & Tote", "Vertical Lift - Pallet","Continuous Lift - Case & Tote"]
  },
  {
    img: product3,
    link:'truck-loading-details',
    title: "Truck Loading Solutions",
    list: ["Inclined Loading Conveyor", "Expandable Conveyor", "Telescopic Conveyor"]
  },
  {
    img: product4,
    link:'smart-conveying-details',
    title: "Smart Conveying Solutions",
    list: ["Spiral - Belt & Gravity","Belt & Roller Conveyor's","Roller & Chain Conveyor's"]
  },
  {
    img: product5,
    link:'conveying-details',
    title: "Conveying Accessories",
    list: ["Trun Tables - Tote, Case & Pallet","Cross Transfer - Tote, Case & Pallet","Pallet Stacker & Destacker","Dimension Check Module"]
  },
  {
    img: product6,
    link:'iiot-analytics-details',
    title: " IIoT & Analytics​",
    list: ["Asset Access Control - AAC​", "Equipment Health Manager - EHM","Plant Energy Manager - PEM","Operational Efficiency Manager - OEM"]
  }
];