import React, { useEffect, useState } from "react";
import smart_bnr from "../../images/product/product_details/smartRoboticBnr.webp";
import smartRobotic from "../../images/product/product_details/smartRobotic.png";
import icon1 from "../../images/product/icons/palletize.png";
import icon2 from "../../images/product/icons/depalletize.png";
import icon3 from "../../images/product/icons/packing.png";
import icon4 from "../../images/product/icons/picking.png";
import catelog1 from "../../pdfCattalog/RoboticGantryPalletizingCell.pdf";
import catelog2 from "../../pdfCattalog/RoboticGantryDepalletizingCell.pdf";
import catelog3 from "../../pdfCattalog/Robotic GantryPick&PlaceCell.pdf";
import catelog4 from "../../pdfCattalog/RoboticGantryPackagingCell.pdf";
import smartroboticMobileView from "../../images/product/product_details/smartroboticMobileView.webp";
import { FaDownload } from "react-icons/fa6";
import DilogueBox from "../../component/DilogueBox";

export const SmartRoboticDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

// Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? smartroboticMobileView : smart_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                 Smart Robotic Solutions
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Leveraging advanced technologies, Airisense Technologies
                  introduces dynamic, high-performance industrial robotic
                  solutions adept at intricate operations, eliminating
                  bottlenecks, and seamlessly adapting to industry growth and
                  challenges.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Our robotics experts provide tailored solutions, enhancing
                  operational speed, precision, and efficiency in tasks like
                  packing, order processing, palletizing, depalletizing,
                  loading, and unloading. Leveraging industry knowledge, we
                  optimize productivity, adapt to workforce availability, and
                  drive business expansion through 24/7 operations.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  By integrating robotics seamlessly, we ensure intelligent
                  augmentation to your workforce, offering round-the-clock
                  working capacity and the advantages of fully automated,
                  reliable operations.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-lg-5 ">
              <div className="about_us_img ">
                <img
                  src={smartRobotic}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                }}
                className="text-center text-white display-4"
              >
                Revolutionizing Automation with Smart Robotics
              </h1>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Robotic/Gantry Palletizing Cell{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    The Robotic Palletizing Cell, with dedicated pallet and
                    sheet handling systems, prioritizes palletizing tasks for
                    heightened throughputs. Its autonomous operation efficiently
                    organizes various product types with speed, precision, and
                    adaptability.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Robotic/Gantry Depalletizing Cell{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    The advanced Robotic/Gantry Depalletizing Cell stands out
                    with dedicated systems for pallet and sheet handling,
                    enabling the robotic arm to focus on depalletizing tasks,
                    ensuring enhanced throughput and gentle treatment of
                    finished products.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Robotic / Gantry Pick & Place Cell{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Optimize operations with our tailored pick-and-place system,
                    excelling in diverse tasks from loading to food handling.
                    Customized solutions ensure peak performance for unique
                    requirements.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Robotic / Gantry Packaging Cell{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    Revolutionize packaging with our robotic Case Packer
                    solution, providing seamless top-load pick-and-place for
                    bottles, cans, jars, pouches, and more. Enhance efficiency
                    and productivity with cutting-edge technology.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      // href={catelog4}
                      // download
                      onClick={() => openModel(catelog4)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
         {/* Download modal */}
      <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
