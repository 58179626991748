import { Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import axios from "axios";

const DilogueBox = ({ open, setOpen, pdfData, setIsDownload }) => {
  // State to manage form input values
  const [initialValue, setInitialValue] = useState({
    name: "",
    email: "",
    mobile: "",
    query: "pdf",
  });

  // Function to download the PDF file
  const downloadPDF = () => {
    // Trigger PDF download
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); // You can set a default file name here
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    // Optionally close the modal
    setOpen(false);
  };

  // Function to handle form submission
  const submitForm = async (e) => {
    e.preventDefault();

    try {
      // Make the API request to save form data
      const response = await axios.post(
        "https://airi-sense.co.in/backend/api/query/add",
        initialValue
      );
      console.log("API response:", response.data);
      // Set local storage flag to indicate successful download
      localStorage.setItem("is_download", true);
      // Update state to trigger download
      setIsDownload(true);
      // Download the PDF
      downloadPDF();
      // Reset form data
      setInitialValue({
        name: "",
        email: "",
        mobile: "",
        query: "pdf",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  // Function to handle input changes
  const inputChange = (e) => {
    const { name, value } = e.target;
    setInitialValue({ ...initialValue, [name]: value });
  };

  return (
    <div>
      {/* Modal to enter user details */}
      <Modal show={open} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpen(false)}>
          <Modal.Title className="fs-3 fw-bolder text-dark">
            Enter Your Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitForm} className="mt-3 mx-4">
            <Row className="mb-4">
              <Col
                xs={6}
                md={4}
                lg={4}
                className="text-start text-muted fs-5 fw-bold"
              >
                <Form.Label>Full Name</Form.Label>
              </Col>
              <Col xs={12} md={8} lg={8}>
                <FormControl
                  type="text"
                  name="name"
                  value={initialValue.name}
                  placeholder="Abc Xyz"
                  autoFocus
                  onChange={inputChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                xs={6}
                md={4}
                lg={4}
                className="text-start text-muted fs-5 fw-bold"
              >
                <Form.Label>Email Address</Form.Label>
              </Col>
              <Col xs={12} md={8} lg={8}>
                <Form.Control
                  name="email"
                  type="email"
                  value={initialValue.email}
                  placeholder="name@example.com"
                  autoFocus
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  onChange={inputChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                xs={6}
                md={4}
                lg={4}
                className="text-start text-muted fs-5 fw-bold"
              >
                <Form.Label>Mobile Number</Form.Label>
              </Col>
              <Col xs={12} md={8} lg={8}>
                <FormControl
                  name="mobile"
                  type="text"
                  value={initialValue.mobile}
                  placeholder="123456789"
                  pattern="^\d{10}$"
                  required
                  autoFocus
                  onChange={inputChange}
                />
              </Col>
            </Row>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DilogueBox;
