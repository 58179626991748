import React, { useState, useEffect } from "react";
import carrer_bnnr from "../images/carrer_bnnr.webp";
import graduates from "../images/graduates.svg";
import location from "../images/location.svg";
import professionals from "../images/professionals.svg";
import students from "../images/students.svg";
import careerSide from "../images/career/car-side.webp";
import carrerMobileview from "../images/carrerMobileview.webp";
import { Link } from "react-router-dom";

export const Carrers = () => {
  const items = [
    {
      title: "Experienced Professionals",
      imgSrc: professionals,
      backgroundColor: "#00a4ef",
      link: "current-openings",
    },
    {
      title: "Trainee",
      imgSrc: graduates,
      backgroundColor: "var(--orange)",
      link: "current-openings",
    },
    {
      title: "Students/Internships",
      imgSrc: students,
      backgroundColor: "#5ca904",
      link: "current-openings",
    },
  ];
  const [activeTab, setActiveTab] = useState("Graduates");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <section id="our_pride">
        <div className="bnrImg">
          <img
            src={isMobileView ? carrerMobileview : carrer_bnnr}
            width="100%"
            height="auto"
          />
        </div>
      </section>
      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row">
            <div className=" col-md-12 col-sm-12 col-lg-7 mb-lg-5 pe-lg-5">
              <div className="whatWeDo pt-lg-5">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item pt-lg-3"
                >
                  Working at Airi-Sense
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Embark on a transformative career journey with Airi-Sense
                  Technologies, a pioneering force in robotic and automation
                  technology. We welcome ambitious individuals to join our
                  dynamic team and immerse themselves in cutting-edge technology
                  development. Your role will involve actively contributing to
                  revolutionary solutions that redefine industries and shape the
                  future.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  At Airi-Sense, we offer unparalleled materials handling
                  technology, meticulously tailored to industrial needs, covering the
                  entire process from receiving to shipping. Join us in
                  transcending boundaries and becoming an integral part of the
                  innovation that defines the future at Airi-Sense Technologies.
                  Elevate your career and contribute to groundbreaking
                  advancements in the world of automation.
                </p>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 ps-lg-3 pt-lg-5">
              <div className="about_us_img pt-lg-5">
                <div
                  className="d-lg-block"
                  style={{
                    position: "absolute",
                    borderRight: "3px solid var(--orange)",
                    height: "400px",
                    marginLeft: "-45px",
                    marginTop: "6px",
                    boxShadow: "#a75911e6 7px 6px 16px 1px",
                  }}
                ></div>
                <img
                  src={careerSide}
                  className="image-fluid rounded "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 whatWeDo">
            <h1
              style={{
                fontWeight: "600",
                letterSpacing: "3px",
              }}
              className="text-start list-group-item text-center"
            >
              Explore Opportunities
            </h1>
            <p className="card-paragraph mt-4" style={{ textAlign: "justify" }}>
              The culture of creative problem-solving and empowerment at
              Airi-Sense is indeed appealing for individuals seeking a dynamic
              and rewarding career path. If you are enthusiastic about
              contributing to the company's success and personal growth, and you
              are interested in building your career in such an environment, we
              encourage you to get in touch and submit your resume. Your skills
              and passion for innovation can be a valuable asset to the company,
              and this could be the start of an exciting and fulfilling journey
              with Airi-Sense.
            </p>
            <p className="card-paragraph mt-4" style={{ textAlign: "justify" }}>
              We wholeheartedly back employees who aspire to make a significant
              impact in their chosen fields by channeling their passion and
              energy. Our guidance and mentorship provide them with the
              essential tools to explore new career horizons and reach their
              full potential.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center py-lg-3">
          {items.map((item, index) => (
            <div
              className={`col-lg-4 col-md-4 col-sm-6 d-flex direction-column py-3 ps-2 wow fadeInLeft animated position-relative`}
              data-wow-delay={item.delay}
              key={index}
              onClick={() => setActiveTab(item.title)}
            >
              <Link to={item.link}>
                <div
                  className={`stories-txt ${item.className} py-2 me-3`}
                  style={{ backgroundColor: item.backgroundColor }}
                >
                  <a
                    className={item.color}
                    href={item.link}
                    aria-label={`Know about ${item.title}`}
                    title={item.title}
                  >
                    <img src={item.imgSrc} alt={item.title} className="w-100" />
                    <p
                      className="highlight-text w-100 text-start text-white"
                      style={{
                        position: "absolute",
                        left: "25px",
                        bottom: "139px",
                        fontSize: "20px",
                      }}
                    >
                      <strong>{item.title}</strong>
                    </p>
                  </a>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
