const blogData = [
    {
      id: 1,
      img: '../Assests/img/Adani.webp',
      photo: '12 march',
      time: '2 minutes',
      comment: '27 comments',
      title: 'Blogs 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
   
    },
    {
        id: 2,
        img: '../Assests/img/Adani.webp',
        photo: '20 feb',
        time: '2 minutes',
        comment: '27 comments',
        title: 'Blogs 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
       
      },
      {
        id:3,
        img: '../Assests/img/Adani.webp',
        photo: 'author 3',
        time: '2 minutes',
        comment: '27 comments',
        title: 'Blogs 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        day: '12',
        month: 'March',
      },
      {
        id: 4,
        img: '../Assests/img/Adani.webp',
        photo: 'authour 4',
        time: '2 minutes',
        comment: '27 comments',
        title: 'Blogs 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        day: '12',
        month: 'March',
      },
      {
        id: 5,
        img: '../Assests/img/Adani.webp',
        photo: 'author 5',
        time: '2 minutes',
        comment: '27 comments',
        title: 'Blogs 5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        day: '12',
        month: 'March',
      },
      {
        id: 6,
        img: '../Assests/img/Adani.webp',
        photo: 'author',
        time: '2 minutes',
        comment: '27 comments',
        title: 'Blogs 6',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        day: '12',
        month: 'March',
      },
  
  ];
  
  export default blogData;
  