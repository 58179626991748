import new1 from "../images/new1.png";
import new3 from "../images/new3.webp";
import new2 from "../images/new2.png";

const newupdate = [
  {
    id: 1,
    img: new1,
    date: "3 Jan",
    des:"Indias's Industrial Robotics Set to Skyrocket: Anticipated Surge from 3,200 to 28,000 Units by 2030, Featuring 2,000 Collaborative Robots!",
    link:"https://www.thehindu.com/business/india-lags-in-industrial-automation-despite-being-tech-leader/article66337678.ece"
  },
  {
    id: 2,
    img: new2,
    date: "4 Jan",
    des: "Ensuring Produce Safety: Unveiling the Power of IoT-Enabled Condition Monitoring Technology.",
    link:"https://www.automation.com/en-us/articles/january-2024/iot-enabled-condition-monitoring-tech-produce?listname=Automation%20&%20Control%20News%20&%20Articles"

  },
  {
    id: 3,
    img: new3,
    date: "4 Jan",
    des: "RSP Ventures Into India's $13 Billion Robotics and Automation Market, Set to Ignite Manufacturing Revolution in the Coming Year!",
    link:"https://economictimes.indiatimes.com/industry/indl-goods/svs/engineering/swedish-robot-system-products-taps-into-indias-13-billion-robotics-and-automation-market-to-start-manufacturing-next-year/articleshow/105408391.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst"

  },
];

export default newupdate;
