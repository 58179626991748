export const palletizer_trends = [
  {
    id: 1,
    heading: "End-of-Arm Tooling Innovation",
    des: "Advancements in end-of-arm tooling technology, such as adaptive grippers and suction cups, enable robots to handle a wider variety of products and palletize items with different shapes and sizes more efficiently."
  },

  {
    id: 2,
    heading: "Integration of Artificial Intelligence ",
    des: " AI algorithms are being integrated into robotic palletizing systems for improved pattern recognition, decision-making, and optimization of palletizing processes. This enhances the adaptability and intelligence of robotic palletizers.",
  },

  {
    id: 3,
    heading: "Increased Payload Capacities",
    des: "The development of robotic palletizers with higher payload capacities allows them to handle heavier and bulkier items, expanding their application in various industries.",
  },

  {
    id: 4,
    heading: "Collaborative Palletizing",
    des: "Collaborative robots (cobots) are increasingly being used in palletizing applications, working alongside human workers to enhance flexibility and efficiency in mixed human-robot environments.",
  },

  {
    id: 5,
    heading: "Enhanced Vision Systems",
    des: "Advanced vision systems and 3D cameras are employed for accurate palletizing of irregularly shaped items. This technology enables robots to identify, pick, and place products with precision.",
  },

  {
    id: 6,
    heading: "Flexible and Modular Palletizing Solutions",
    des: "Palletizing systems are becoming more modular and scalable, allowing for easy integration into existing production lines and adapting to changing operational needs.",
  },

  {
    id: 7,
    heading: "Palletizing Software Advancements",
    des: "Improved palletizing software offers intuitive programming interfaces, making it easier for operators to set up and modify palletizing patterns without extensive programming knowledge.",
  },

  {
    id: 8,
    heading: "Mobile Robotic Palletizing",
    des: "The emergence of mobile robotic palletizing solutions that can be easily transported between different locations within a facility. This enhances the flexibility of palletizing operations.",
  },

  {
    id: 9,
    heading: "Internet of Things (IoT) Connectivity",
    des: "IoT integration enables real-time monitoring, data analytics, and predictive maintenance for robotic palletizing systems, improving overall operational efficiency.",
  },

  {
    id: 10,
    heading: "Palletizing for E-commerce Fulfillment",
    des: "With the growth of e-commerce, robotic palletizing systems play a crucial role in efficiently handling the palletization of goods in distribution centers and warehouses",
  },

  {
    id: 11,
    heading: "Energy Efficiency",
    des: "There is an increased focus on developing energy-efficient robotic palletizing solutions to reduce operational costs and environmental impact.",
  },

  {
    id: 12,
    heading: "Remote Monitoring and Control",
    des: "The ability to remotely monitor and control robotic palletizing systems using cloud-based platforms or mobile applications is becoming more prevalent, enabling better management and troubleshooting",
  },
];

export const palletize_benefits = [
  {
    id: 1,
    heading: "Labor cost savings",
  },

  {
    id: 2,
    heading: "Increased line flexibility",
  },

  {
    id: 3,
    heading: "Increased throughput",
  },

  {
    id: 4,
    heading: "Reduced worker injuries",
  },

  {
    id: 5,
    heading: "Increased load accuracy – quality",
  },

  {
    id: 6,
    heading: "Better cube utilization",
  },

  {
    id: 7,
    heading: "Tighter, more uniform pallet loads",
  },

  {
    id: 8,
    heading: "Increased line speed",
  },

  {
    id: 9,
    heading: "Reduced handling-related product damage",
  },
];
