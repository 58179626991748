import React, { useEffect, useState } from "react";
import "./page.css";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { BsYoutube, BsFillTelephoneFill } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import contact from "../images/contact.webp";
import { BASE_URI } from "../Utils/constents";
import Swal from "sweetalert2";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("query");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const resp = await fetch(`${BASE_URI}query/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message, type }),
      });

      const data = await resp.json();
      if (!resp.ok) {
        throw new Error("Failed to submit the form");
      } else {
        console.log(data.message);
        setName("");
        setEmail("");
        setMessage("");
        setType("");
        Swal.fire({
          icon: "success",
          title: `Query`,
          text: data.message,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="section-header ">
        <div className="bnrImg">
          <img src={contact} width="100%" height="auto" />
        </div>
      </div>
      <section class="contact_us">
        <div class="row justify-content-center">
          <div class="col-md-12 ">
            <div class="contact_inner">
              <div class="row justify-content-center">
                <div class="col-md-10">
                  <div class="contact_form_inner">
                    <div class="contact_field whatWeDo">
                      <h1>Contact Us</h1>
                      <p className="">
                        Feel Free to contact us any time. We will get back to
                        you as soon as we can!
                      </p>

                      <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInputName"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInputName">Name</label>
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInputEmail"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInputEmail">
                            Email address
                          </label>
                        </div>

                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                          ></textarea>
                          <label htmlFor="floatingTextarea2">Message</label>
                        </div>

                        <button className="contact_form_submit" type="submit">
                          Send
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="right_conatct_social_icon d-flex align-items-end">
                    <div class="socil_item_inner d-flex">
                      <li>
                        <a href="#">
                          <AiFillLinkedin className="fs-4" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <AiOutlineTwitter className="fs-4" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <BsYoutube className="fs-4" />
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="contact_info_sec">
                <h4>Contact Info</h4>
                <div class="d-flex info_single text-start">
                  <BsFillTelephoneFill className="me-3 fs-5" />
                  <span>
                    <a
                      href="tel:7906626681"
                      className="text-white text-decoration-none"
                    >
                      79066 26681
                    </a>
                  </span>
                </div>
                <div class="d-flex info_single text-start">
                  <BiLogoGmail className="me-3 fs-5" />
                  <span>automate@airi-sense.co.in</span>
                </div>
                <div class="d-flex info_single text-start">
                  <FaLocationDot className="me-3 fs-5" />
                  <span>
                    162 A Prime City Veena Nagar NX, Sukhliya, Indore, M.P.
                    452010
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="map_sec">
        <div class="row justify-content-center">
          <div class="col-md-10  ">
            <div class="map_inner whatWeDo">
              <h1 className="text-center">Find Us on Google Map</h1>

              <div class="map_bind">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.2782078753785!2d75.8662135234607!3d22.755054425986437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302995f981525%3A0x882c20e8afd869f5!2s162%2C%20Prime%20City%20Rd%2C%20Ambe%20Nagar%2C%20Sukhlia%2C%20Indore%2C%20Madhya%20Pradesh%20452011!5e0!3m2!1sen!2sin!4v1698917891929!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
