export const packaging_trends = [
  {
    id: 1,
    heading: "Collaborative Robots (Cobots)",
    des: "The use of collaborative robots that can work alongside human workers continues to rise. These robots are designed to enhance efficiency and safety by collaborating with human operators in the packaging process.",
  },

  {
    id: 2,
    heading: "Artificial Intelligence (AI) Integration ",
    des: " Robotic packaging systems are increasingly incorporating AI algorithms for tasks such as image recognition, quality control, and decision-making. This enhances the adaptability and intelligence of robotic systems.",
  },

  {
    id: 3,
    heading: "Machine Learning for Optimization ",
    des: " Machine learning algorithms are being applied to robotic packaging systems to optimize tasks like pick-and-place operations. These systems can learn and improve their performance over time based on data and feedback.",
  },

  {
    id: 4,
    heading: "Vision Systems ",
    des: " Advanced vision systems are employed for precise object recognition and tracking, enabling robots to handle a wide variety of products with different shapes and sizes efficiently",
  },

  {
    id: 5,
    heading: "Modularity and Scalability ",
    des: " Robotic packaging solutions are becoming more modular and scalable, allowing businesses to easily expand or reconfigure their systems based on changing production needs.",
  },

  {
    id: 6,
    heading: "Internet of Things (IoT) Connectivity ",
    des: "IoT integration facilitates real-time monitoring and data exchange between robotic packaging systems and other connected devices, enabling remote management and predictive maintenance.",
  },

  {
    id: 7,
    heading: "Sustainability Focus",
    des: "Robotic packaging is aligning with sustainability goals by optimizing packaging materials, reducing waste, and contributing to more environmentally friendly practices. ",
  },

  {
    id: 8,
    heading: "E-commerce Fulfillment Automation",
    des: "With the growth of e-commerce, there's an increased focus on robotic solutions for order fulfillment and packaging in distribution centers. Robots play a crucial role in handling high volumes of diverse products efficiently.",
  },

  {
    id: 9,
    heading: "Enhanced Gripping Technology ",
    des: "Developments in end-of-arm tooling and gripping technologies allow robots to handle delicate and irregularly shaped items more effectively, expanding the range of products that can be automated.",
  },

  {
    id: 10,
    heading: "Robot as a Service (RaaS): ",
    des: "Some companies are exploring the RaaS model, offering robotic packaging solutions on a subscription basis. This allows businesses to access advanced robotic capabilities without significant upfront investments.",
  },

  {
    id: 11,
    heading: "Adaptive and Flexible Programming",
    des: "The trend is towards more user-friendly and adaptable programming interfaces, making it easier for non-experts to program and reprogram robotic systems for various packaging tasks.",
  },

  {
    id: 12,
    heading: "Edge Computing for Real-time Processing ",
    des: "Edge computing is being applied to robotic packaging systems for real-time processing of data generated during packaging operations. This improves decision-making speed and reduces latency.",
  },
];

export const package_concept = [
  {
    id: 1,
    heading: "Automation",
    des: "Automated packaging involves the use of robots to perform various packaging tasks, such as picking, placing, sorting, and labeling. Automation enhances efficiency by reducing the reliance on manual labor and streamlining the packaging workflow.",
    background: "var(--orange)",
  },

  {
    id: 2,
    heading: "Precision and Consistency",
    des: "Robots are equipped with advanced sensors and programming capabilities, allowing them to handle packaging tasks with precision and consistency. This ensures uniformity in packaging, minimizing errors and enhancing the overall quality of the packaged products.",
    background: "var(--footerbackground)",
  },

  {
    id: 3,
    heading: "Adaptability",
    des: "Robotic packaging systems are designed to be adaptable to different product sizes, shapes, and packaging requirements. The flexibility of robotic arms and end-of-arm tools allows for quick adjustments to accommodate diverse product lines.",
    background: "var(--orange)",
  },

  {
    id: 4,
    heading: "Speed and Throughput",
    des: "Robots, operating at high speeds, enhance packaging throughput, meeting production demands, and ensuring efficient supply chain processes. Their rapid performance is vital for sustaining productivity and meeting the demands of a streamlined supply chain.",
    background: "var(--footerbackground)",
  },

  {
    id: 5,
    heading: "Customization",
    des: "The concept of robotic packaging enables customization to meet specific industry and product packaging needs. Robots can be programmed to handle unique packaging challenges, providing tailored solutions for different applications.",
    background: "var(--orange)",
  },

  {
    id: 6,
    heading: "Integration with Technology",
    des: "Robotic packaging often involves the integration of robotics with other advanced technologies, such as vision systems, sensors, and machine learning.This integration enhances the capabilities of robotic systems for improved decision-making and adaptability.",
    background: "var(--footerbackground)",
  },

  {
    id: 7,
    heading: "Enhanced Safety",
    des: "Enhances workplace safety by undertaking tasks in hazardous environments, reducing the risk of injuries to human workers. This ensures a secure work environment, demonstrating the pivotal role of robotics in minimizing potential workplace hazards.",
    background: "var(--orange)",
  },

  {
    id: 8,
    heading: "Data-driven Insights",
    des: "Many robotic packaging systems are equipped with monitoring and reporting features, providing real-time data on the packaging process. This data-driven approach allows for proactive problem-solving, reducing downtime and optimizing overall equipment effectiveness.",
    background: "var(--footerbackground)",
  },

  {
    id: 9,
    heading: "Sustainability",
    des: "Embodies sustainability by optimizing material use, reducing waste, and promoting eco-friendly practices. This aligns with the commitment to environmentally conscious packaging, contributing to a greener and more sustainable approach in the industry.",
    background: "var(--orange)",
  },
];

export const package_benefits = [
  {
    id: 1,
    heading: "Increased Efficiency",
    des: "Boosts speed, throughput, and production output significantly.",
  },
  {
    id: 2,
    heading: "Precision and Accuracy",
    des: "Ensures error reduction for consistent product quality.",
  },
  {
    id: 3,
    heading: "Cost Savings",
    des: "24/7 operation lowers labor costs, enhancing overall productivity.",
  },
  {
    id: 4,
    heading: "Flexibility and Adaptability",
    des: "Easily reprogrammed for diverse packaging tasks and products.",
  },
  {
    id: 5,
    heading: "Improved Safety",
    des: "Minimizes risks in hazardous environments, promoting worker well-being.",
  },
  {
    id: 6,
    heading: "Space Optimization",
    des: "Compact design fits tight spaces, optimizing production floor layout.",
  },
  {
    id: 7,
    heading: "Consistent Quality",
    des: "Automation reduces variations, ensuring uniform and high-quality packaging.",
  },
  {
    id: 8,
    heading: "Real-time Monitoring",
    des: "Equipped with sensors for quick issue identification, minimizing downtime.",
  },
  {
    id: 9,
    heading: "Traceability Improvement",
    des: "Integration with tracking tech enhances product traceability in packaging.",
  },
  {
    id: 10,
    heading: "Regulatory Compliance",
    des: "Automated systems programmed to meet industry standards and regulations.",
  },
];
