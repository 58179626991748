import React, { useState, useEffect } from "react";
import solution_bnr from "../../images/solution/solution_details/pallentizing_bnr.png";
import solution_comman from "../../images/solution/solution_details/palletizing-cmn.webp";
import solution1 from "../../images/solution/solution_details/palletize/center.webp";
import solution2 from "../../images/solution/solution_details/palletize/d-center.webp";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import palletizingMobileView from "../../images/solution/solution_details/palletizingMobileView.webp";
import { AiFillCheckCircle } from "react-icons/ai";
import { palletize_benefits, palletizer_trends, trends } from "../../component/PalletizeData";

export const PalletizingDetails = () => {
  const [activePanel, setActivePanel] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const setActive = (number) => {
    if (activePanel == number) {
      setActivePanel(null);
    } else {
      setActivePanel(number);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? palletizingMobileView : solution_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="container py-lg-5 px-lg-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className=" text-white">
                <h1 style={{ textAlign: "justify", fontWeight: "400" }}>
                Why automating the palletizing process?
                </h1>
                <p
                  className="mt-4 w-100  fs-3  fw-light "
                  style={{ textAlign: "justify" }}
                >
                  Justification of an automatic palletizer should be verified
                  using following benefits in the ROI (Return on Investment)
                  calculation
                </p>
              </div>
            </div>

            <div className="row whatWeDo">
              {palletize_benefits.map((item, index) => (
                <div className="d-flex  col-lg-6 ">
                  <span className="h4">
                    <AiFillCheckCircle style={{ color: "var(--white)" }} />
                  </span>
                  <p className=" ps-2 text-white">{item.heading}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }} className="pb-lg-0 pb-4">
        <h1 className="pt-lg-4" style={{ fontWeight: "400" }}>
          Categories Of Palletizers
        </h1>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-6">
              <h3 className="py-2" style={{ fontWeight: "400" }}>
                Centralized Palletizer
              </h3>
              <div className="about_us_img " style={{ height: "450px" }}>
                <img
                  src={solution1}
                  className="image-fluid "
                  width="75%"
                  alt="productdescription"
                />
              </div>
              <div className="ps-5" style={{ textAlign: "justify" }}>
                <h5 style={{ fontWeight: "400" }}>
                  Example of one robot serving 7 lines from 7{" "}
                </h5>
                <ul className="fs-6" style={{ listStyleType: "disc" }}>
                  <li className="mt-2">packaging machines (grey rectangles)</li>
                  <li className="mt-2">
                    Elevators (yellow) to move products up to conveyor system
                    under the ceiling
                  </li>
                  <li className="mt-2">Buffer system (blue)</li>
                  <li className="mt-2">
                    Robot (orange) with one gripper docking another
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-6">
              <h3 className="py-2" style={{ fontWeight: "400" }}>
                Decentralized Palletizer
              </h3>
              <div className="about_us_img " style={{ height: "450px" }}>
                <img
                  src={solution2}
                  className="image-fluid"
                  width="75%"
                  alt="productdescription"
                />
              </div>
              <div className="ps-5" style={{ textAlign: "justify" }}>
                <h5 style={{ fontWeight: "400" }}>
                  Example of one robot serving 1 line
                </h5>
                <ul className="fs-6" style={{ listStyleType: "disc" }}>
                  <li className="mt-2">packaging machines (grey rectangles)</li>
                  <li className="mt-2">
                    Reduces capacity compared to left solution, minimizing
                    pre-forming of patterns.
                  </li>
                  <li className="mt-2">
                    Optimal efficiency arises from combining pre-formed simple
                    patterns and robotic formation for complex ones.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }} className="pb-lg-0 pb-4">
          <h1 style={{fontWeight:"400"}} className="pt-lg-4">Trends of pallentizers</h1>
        <div className="container">
          <div className="row pb-5 justify-content-lg-center">
          {palletizer_trends.map((item, index) => (
            <div className="col-lg-6">
              <div className=" d-lg-flex  flex-lg-column">
              <div id="accordion" className="trendcard px-lg-3"  key={index}>
               
                  <div
                    className={`text-start shadow mt-4 ${
                      activePanel === index + 1 ? "active" : ""
                    }`}
                    style={{
                      backgroundColor: "var(--orange)",
                      border: "solid var(--white)",
                    }}
                  >
                    <div className="card-body px-2 py-2" >
                      <a
                        onClick={() => setActive(index + 1)}
                        className="fs-5 d-flex justify-content-between"
                        style={{  
                          color: "var(--white)",
                          textDecoration: "none",
                          fontWeight:"400px"
                        }}
                        data-bs-toggle="collapse"
                        href={`#collapse${index + 1}`}
                        aria-expanded={activePanel === index + 1}
                        aria-controls={`collapse${index + 1}`}
                      >
                        <span>{item.heading}</span>
                        {activePanel !== index + 1 ? (
                          <BiPlusMedical className="me-lg-3 mt-2" />
                        ) : (
                          <FaMinus className="me-lg-3 mt-2" />
                        )}
                      </a>
                      <p
                        className="collapse text-white pt-3 fs-6"
                        id={`collapse${index + 1}`}
                        data-bs-parent="#accordion"
                        style={{ backgroundColor: "var(--orange)", textAlign:"justify",transition: "all 0s ease-in-out 0s"
                       }}
                      >
                        {item.des}
                      </p>
                    </div>
                  </div>
               
              </div>
              </div>
             
              
            </div>
             ))}
          </div>
        </div>
      </div>
    </>
  );
};
