import React,{useEffect} from "react";
import about_bnr from "../images/about_bnr.webp";
import "./About.css";
import mission from "../images/mission.webp";
import vision from "../images/vision.webp";
import aboutHistory from "../images/aboutHistor.webp";
import val1 from "../images/values/agility.webp";
import val2 from "../images/values/inovation.webp";
import val3 from "../images/values/respect.webp";
import val4 from "../images/values/people.webp";
import val5 from "../images/values/customer.webp";
import val6 from "../images/values/intgrity.webp";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bnrImg">
        <img src={about_bnr} width="100%" height="auto" />
      </div>

      <div className=" py-lg-5 pt-3" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container">
          <div className="row justify-content-center whatWeDo">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <h1
                className="fw-bold text-start"
                style={{ color: "#128c7e", letterSpacing: "6px" }}
              >
                History
              </h1>
              <div className="pt-2">
                <p className="mt-lg-3 ">
                  Airi-Sense, born in December 2022 and bolstered by the
                  extensive expertise of Vasant Engineer's, embarks on a
                  promising journey. With a historical foundation dating back to
                  2003, Airi-Sense was established with a clear vision: to offer
                  cutting-edge solutions in material handling, automation, and
                  robotics to its existing clientele, while also expanding its
                  reach to new customers.
                </p>
                <p className="mt-2">
                  Over time, it became evident that many clients faced material
                  handling and management challenges that current market
                  offerings couldn't adequately address. In response, Airi-Sense
                  entered the scene with a fresh perspective and a team of
                  seasoned experts who possess deep knowledge in material
                  handling and management. This team integrates traditional
                  wisdom with the latest advancements in state-of-the-art
                  robotics, with the aim of delivering innovative and customized
                  solutions that precisely align with the unique requirements of
                  clients across diverse industries.
                </p>
                <p className="mt-2">
                  The company's resolute commitment to providing effective and
                  tailored solutions underscores its dedication to driving
                  progress in the realms of material handling, automation, and
                  robotics. This commitment positions Airi-Sense as a valuable
                  partner for businesses seeking to elevate their operational
                  efficiency and productivity, ensuring a bright future for both
                  the company and its clients.
                </p>
              </div>
            </div>
            <div className=" pt-lg-5 col-lg-4 col-md-12 col-sm-12 ps-lg-3">
              <div className="pt-lg-4 pt-lg-4">
                <img src={aboutHistory} width="100%" height="600px" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutRoot text-white">
        <div className="py-lg-4 ">
          <div className="row justify-content-center  py-5">
            <div className="col-md-8 card px-4 py-3 shadow ">
              <div className="py-4 ourroootSection ">
                <h2 className="text-center fw-bold">Our Root's</h2>
                <h3 className="text-center" style={{ letterSpacing: "3px" }}>
                  Shri Vasant Engineer's
                </h3>

                <p className="text-justify outRoot">
                  Emerging from its well-established parent company, Vasant
                  Engineer's, which boasts a 20-year legacy that dates back to
                  2003 in Indore, Airi-Sense is now venturing into the Material
                  Handling and Material Management & Robotics Automation sector
                  with renewed determination and enthusiasm.
                </p>
                <p className="text-justify outRoot pb-3">
                  Our extensive clientele, which surpasses the impressive number
                  of 233, is a diverse mix of global enterprises, multinational
                  corporations, and influential Indian conglomerates, covering a
                  wide spectrum of industrial sectors. What's particularly
                  remarkable is that more than 103 of these esteemed clients
                  continue to place their trust in us year after year,
                  solidifying our position as their preferred partner for
                  enduring and mutually beneficial business relationships
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-5 mission">
        <div className="row clearfix pb-lg-3">
          <div className="col-md-3 hover-zoom pt-2 mt-lg-3 ps-3">
            <div className="img-back text-end">
              <img src={mission} alt="" className="w-100" />
            </div>
          </div>
          <div className="col-md-8 mt-lg-3 pt-lg-5 whatWeDo ">
            <h1
              className="py-3 text-center fw-bold"
              style={{ color: "#128c7e", letterSpacing: "3px" }}
            >
              Mission
            </h1>
            <p className="py-2 px-3">
              Our mission is to empower our customers to reshape their material
              handling, material management, and automation strategies, enabling
              them to achieve a new level of operational excellence.
            </p>
          </div>
        </div>
      </div>

      <div className="container  vision">
        <div className="row clearfix pb-lg-5 justify-content-end ">
          <div className="col-md-8 mt-lg-3 pt-lg-5 whatWeDo ">
            <h1
              className="pb-3 text-center fw-bold"
              style={{ color: "#128c7e", letterSpacing: "3px" }}
            >
              Vision
            </h1>
            <p className=" py-2 px-3">
              Our vision is to lead the way in the fields of material handling,
              material management, and robotic automation by delivering
              innovative products and cutting-edge software solutions.
            </p>
          </div>

          <div className="col-md-3 hover-zoom pt-2 mt-lg-3">
            <div className="img-back text-start">
              <img src={vision} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="values  py-5 mt-5 aboutRoot">
        <div className="row pb-3  ">
          <div className="col-md-12">
            <center>
              <h1
                className="text-light fw-bold text-white"
                style={{
                  fontSize: "55px",
                  color: "white !important",
                  letterSpacing: "3px",
                }}
              >
                Values
              </h1>
            </center>
          </div>
        </div>

        <div className="about">
          <a
            className="bg_links social portfolio"
            href="https://www.rafaelalucas.com"
            target="_blank"
          >
            <span className="icon"></span>
          </a>
          <a
            className="bg_links social dribbble"
            href="https://dribbble.com/rafaelalucas"
            target="_blank"
          >
            <span className="icon"></span>
          </a>
          <a
            className="bg_links social linkedin"
            href="https://www.linkedin.com/in/rafaelalucas/"
            target="_blank"
          >
            <span className="icon"></span>
          </a>
          <a className="bg_links logo"></a>
        </div>

        <div className="content ">
          <div className="valuecard">
            <div className="icon">
              <img src={val1} />
            </div>
            <p className="title">Agility</p>
            <p className="text">
              Adapting swiftly and rejuvenating oneself in response to rapid
              changes is a key skill for navigating today's dynamic world.
            </p>
          </div>
          <div className=" valuecard">
            <div className="icon">
              <img src={val2} />
            </div>
            <p className="title">Innovation</p>
            <p className="text">
              Continuously seeking innovative ways to enhance support for
              employees, clients, and the entire organization's success.​
            </p>
          </div>
          <div className=" valuecard">
            <div className="icon">
              <img src={val3} />
            </div>
            <p className="title">Respect</p>
            <p className="text">
              To demonstrate respect, one must practice openness, honesty, and
              act consistently with unwavering integrity.​
            </p>
          </div>
        </div>
        <div className="content mt-3">
          <div className="valuecard">
            <div className="icon">
              <img src={val4} />
            </div>
            <p className="title">People</p>
            <p className="text">
              The invaluable assets of an organization's success include the
              distinctive qualities and perspectives of each individual within
              it.
            </p>
          </div>
          <div className=" valuecard">
            <div className="icon">
              <img src={val5} />
            </div>
            <p className="title">Customer Centricity</p>
            <p className="text">
              We are wholeheartedly dedicated to consistently delivering
              exceptional service, always striving to make our customers happy.{" "}
            </p>
          </div>
          <div className=" valuecard">
            <div className="icon">
              <img src={val6} />
            </div>
            <p className="title">Integrity</p>
            <p className="text">
              Unwavering commitment to embrace accountability and take full
              responsibility for every action to be undertaken.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
