import React from "react";

export default function Conditions() {
  return (
    <>
      <div className="" style={{ background: "#fb8b23" }}>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="terms-header">
              <h2 className="py-2 text-white">Terms & Conditions</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-3">
        <div className="row">
          <div className="col-10 pt-4">
            <div className="terms-heading">
              <h4 className="text-start">Acceptance of Terms</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  By accessing and using this website, you agree to comply with
                  and be bound by these terms and conditions. It states that
                  using the website is conditional on accepting and complying
                  with these Terms. Nothing contained herein shall be deemed to
                  confer any third-party rights or benefits. In the event you do
                  not agree to be bound by these Terms of Use, you may not use
                  nor access this Website.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Change of Terms</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  We reserve the right to modify these terms and conditions at
                  any time. Any changes will be effective immediately upon
                  posting. It is your responsibility to review these terms
                  periodically for updates.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Use of Websites</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  This Website, under the name and style “www.airi-sense.co.in” is an
                  online medium that has been designed to enable visitors,
                  including Current or Potential Customers, Alliance Partners or
                  Potential Alliance Partners, Financial and Industry Analysts,
                  Investors, Vendors / Suppliers, Media & Journalists, Job
                  seekers, Current and Former employees, Researchers or
                  Academicians, and others, to gather information about Airi-sense
                  and to interact with Airi-sense by sharing their queries through
                  the website contact forms.{" "}
                </li>
                <li className="term-list">
                  You may use this website for informational purposes and for
                  engaging with our services, subject to these terms.
                </li>
                <li className="term-list">
                  You must not use this website for any unlawful or prohibited
                  activities.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Privacy Policy</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  Your use of this website is also governed by our Privacy
                  Policy, which can be found [link to your privacy policy]. By
                  using this website, you consent to the collection and use of
                  your information as described in the Privacy Policy.
                </li>
              </ul>
            </div>

            <div className="pt-3 terms-heading">
              <h4 className="text-start">Intellectual Property</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  All content on this website, including but not limited to
                  text, graphics, logos, images, and software, is the property
                  of Airi-sense Technologies Pvt Ltd and is protected by copyright
                  and other intellectual property laws.{" "}
                </li>
                <li className="term-list">
                  You may not reproduce, distribute, modify, or create
                  derivative works from any content on this website without our
                  express written consent.
                </li>
                <li className="term-list">
                  Any use in any manner of manual or automated software,
                  devices, techniques, or other processes/procedures to “crawl”
                  or “spider” any web pages contained in this Website is
                  strictly prohibited. You understand and agree not to
                  supervise, monitor copy, or allow others to supervise,
                  monitor, or copy web pages or the content of this Website. You
                  understand and agree not to frame edit or otherwise replicate
                  the appearance features or functions of this Website. You
                  understand and agree not to take any action or allow anyone to
                  do so that hinders obstructs or interferes with the working of
                  or places an inappropriate load on our infrastructure to host
                  this Website.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Links to Third-Party Websites</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  This website may contain links to third-party websites. These
                  links are provided for your convenience only. We have no
                  control over the content of third-party websites and are not
                  responsible for their content or actions.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Disclaimer</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  This website and its content are provided "as is" without any
                  warranties, express or implied, including but not limited to
                  the implied warranties of merchantability, fitness for a
                  particular purpose, or non-infringement.{" "}
                </li>
                <li className="term-list">
                  {" "}
                  We do not guarantee the accuracy, completeness, or timeliness
                  of the information provided on this website.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Limitation of Liability</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  Airi-sense Technologies Pvt Ltd and its officers, directors,
                  employees, or agents shall not be liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in any way related to your use of this
                  website.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Governing Law</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  These terms and conditions are governed by and construed in
                  accordance with the laws of Indian. Any disputes relating to
                  these terms and conditions will be subject to the exclusive
                  jurisdiction of the courts in India.
                </li>
              </ul>
            </div>
            <div className="pt-3 terms-heading">
              <h4 className="text-start">Contact Information</h4>
              <ul className="pt-2" style={{ listStyleType: "disc" }}>
                <li className="term-list">
                  If you have any questions or concerns about these terms and
                  conditions, please contact us at{" "}
                  <a
                    href="mailto:info.automate@airi-sense.co.in"
                    className="text-decoration-none text-warning"
                  >
                    automate@airi-sense.co.in
                  </a>
                  .
                </li>
                <li className="term-list">
                  By using our website, you acknowledge that you have read,
                  understood, and agreed to these terms and conditions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
